/**
 * @generated SignedSource<<161952504caf5bc61cb81fae73f9204b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrganizationSubscriptionStanding = "trial_expired" | "active" | "paused" | "inactive_for_member" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useSubscriptionStandingActiveOrganizationFragment$data = {
  readonly subscriptionStanding: OrganizationSubscriptionStanding;
  readonly " $fragmentType": "useSubscriptionStandingActiveOrganizationFragment";
};
export type useSubscriptionStandingActiveOrganizationFragment$key = {
  readonly " $data"?: useSubscriptionStandingActiveOrganizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSubscriptionStandingActiveOrganizationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useSubscriptionStandingActiveOrganizationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionStanding",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "2b88da0c874d28e1e29f409b6bc8b108";

export default node;
