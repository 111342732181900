/**
 * @generated SignedSource<<deaec32970dd6d2db38325f783c2fd9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteWebFormSubmissionButtonMutation$variables = {
  id: string;
};
export type DeleteWebFormSubmissionButtonMutation$data = {
  readonly response: {
    readonly deletedWebFormSubmissionId: string | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type DeleteWebFormSubmissionButtonMutation = {
  variables: DeleteWebFormSubmissionButtonMutation$variables;
  response: DeleteWebFormSubmissionButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedWebFormSubmissionId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteWebFormSubmissionButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DeleteWebFormSubmissionResponse",
        "kind": "LinkedField",
        "name": "deleteWebFormSubmission",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteWebFormSubmissionButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DeleteWebFormSubmissionResponse",
        "kind": "LinkedField",
        "name": "deleteWebFormSubmission",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedWebFormSubmissionId"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ed0c0dfe4048e0573814887f3d50c547",
    "id": null,
    "metadata": {},
    "name": "DeleteWebFormSubmissionButtonMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteWebFormSubmissionButtonMutation(\n  $id: ID!\n) {\n  response: deleteWebFormSubmission(id: $id) {\n    deletedWebFormSubmissionId\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e297b2c6ca07bf84fe24dc0c86fe603f";

export default node;
