import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import WebFormEditorQuestionOptions from "@/web-form/editor/WebFormEditorQuestionOptions"
import WebFormQuestionTypeSelect from "@/web-form/editor/WebFormQuestionTypeSelect"
import WebFormSettings from "@/web-form/editor/WebFormSettings"
import { PollWebFormEditorMutation } from "@/web-form/editor/poll/__generated__/PollWebFormEditorMutation.graphql"
import {
  CreateWebFormInput,
  WebFormEditorProvider,
  cleanWebFormQuestionsInput,
} from "@/web-form/utils/webFormEditorUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import EditorUtils from "@components/editor/EditorUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoFormControl,
  DiscoInput,
  DiscoSection,
  DiscoText,
} from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { graphql } from "relay-runtime"
import { v4 as uuidv4 } from "uuid"

type Props = TestIDProps & {
  onCancel: () => void
  onSave: (webFormId: GlobalID) => void
}

function PollWebFormEditor(props: Props) {
  const { testid = "PollWebFormEditor", onCancel, onSave } = props
  const activeOrganization = useActiveOrganization()!
  const unsavedChangesModal = useUnsavedChangesModalContext()

  const form = useFormStore<PollWebFormEditorMutation, CreateWebFormInput>(
    graphql`
      mutation PollWebFormEditorMutation($input: CreateWebFormInput!) {
        response: createWebForm(input: $input) {
          node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      template: "poll",
      settings: {
        membersSeeAllResults: true,
        endDate: null,
        allowSubmissionDeletion: true,
      },
      questions: [
        {
          uniqueKey: uuidv4(),
          type: "single_select",
          richEditorBody: JSON.stringify(EditorUtils.createParagraphs([""])),
          options: [
            { id: uuidv4(), label: "" },
            { id: uuidv4(), label: "" },
          ],
        },
      ],
    }
  )

  useEffect(() => {
    unsavedChangesModal.setUnsavedChanges(form.isChanged)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.isChanged])

  const questionErrors = form.errorsByField["questions.0"]
  const classes = useStyles({ hasError: Boolean(questionErrors) })

  // Polls only support 1 question
  const question = form.state.questions[0]

  return (
    <WebFormEditorProvider template={"poll"} form={form}>
      {/* Wrap with a Form so pressing Enter inside here doesn't submit any parent forms, */}
      {/* but we also don't want this form to submit on Enter key in the question/options */}
      <Form>
        <DiscoSection border groovyDepths={"insideCard"} padding={0}>
          <div className={classes.header}>
            <DiscoFormControl
              marginBottom={1}
              errorMessages={form.errorsByField["questions.0.type"]}
              disableFullWidth
            >
              <WebFormQuestionTypeSelect
                testid={`${testid}.type`}
                value={question.type}
                onSelect={(type) => (question.type = type)}
                allowedTypes={["single_select", "multiple_select"]}
              />
            </DiscoFormControl>
            <DiscoFormControl
              marginBottom={0}
              errorMessages={form.errorsByField["questions.0.richEditorBody"]}
            >
              <DiscoInput
                data-testid={`${testid}.question.rich-editor-body`}
                placeholder={"Add your question here"}
                // We only allow plain text for poll questions, so convert between lexical
                // and plain text when displaying/updating the question
                value={EditorUtils.convertToPlainText(
                  JSON.parse(question.richEditorBody),
                  {
                    trim: false,
                  }
                )}
                onChange={(e) => {
                  question.richEditorBody = JSON.stringify(
                    EditorUtils.createParagraphs([e.target.value])
                  )
                }}
              />
            </DiscoFormControl>
          </div>
          <div className={classes.middle}>
            <WebFormEditorQuestionOptions
              testid={`${testid}.question`}
              question={question}
              questionIndex={0}
            />
            <div className={classes.settings}>
              <WebFormSettings testid={testid} form={form} />
            </div>
          </div>
          <div className={classes.footer}>
            <div className={classes.questionError}>
              <DiscoText variant={"body-sm"} color={"error.dark"}>
                {questionErrors?.[0]}
              </DiscoText>
            </div>
            <DiscoButton
              testid={`${testid}.cancel`}
              variant={"outlined"}
              color={"grey"}
              onClick={onCancel}
            >
              {"Cancel"}
            </DiscoButton>
            <DiscoButton
              testid={`${testid}.save`}
              onClick={handleSubmit}
              disabled={form.disabled}
              shouldDisplaySpinner={form.isSubmitting}
            >
              {"Save"}
            </DiscoButton>
          </div>
        </DiscoSection>
      </Form>
    </WebFormEditorProvider>
  )

  async function handleSubmit() {
    const { didSave, response } = await form.submit({
      ...form.state,
      questions: cleanWebFormQuestionsInput(form.state.questions),
    })
    if (!didSave || !response?.node) return
    displaySuccessToast({
      message: "Poll created!",
      testid: `${testid}.success`,
    })
    onSave(response.node.id)
  }
}

type StyleProps = {
  hasError: boolean
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
    borderBottom: theme.palette.constants.borderSmall,
  },
  middle: {
    padding: theme.spacing(2),
  },
  footer: ({ hasError }: StyleProps) => ({
    padding: theme.spacing(1.5, 2),
    borderTop: theme.palette.constants.borderSmall,
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "flex-end",
    backgroundColor: hasError
      ? theme.palette.error.light
      : theme.palette.background.paper,
    borderBottomLeftRadius: theme.measure.borderRadius.big,
    borderBottomRightRadius: theme.measure.borderRadius.big,
  }),
  questionError: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  settings: {
    marginTop: theme.spacing(1.5),
  },
}))

export default observer(PollWebFormEditor)
