import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import {
  OrganizationSubscriptionStanding,
  useSubscriptionStandingActiveOrganizationFragment$key,
} from "@utils/hook/__generated__/useSubscriptionStandingActiveOrganizationFragment.graphql"
import { graphql, useFragment } from "react-relay"

/** A hook to determine whether or not a communtiy admin has access to platform */
export default function useSubscriptionStanding() {
  const activeOrganization = useActiveOrganization()
  const organization = useFragment<useSubscriptionStandingActiveOrganizationFragment$key>(
    graphql`
      fragment useSubscriptionStandingActiveOrganizationFragment on Organization {
        subscriptionStanding
      }
    `,
    activeOrganization
  )

  /** If community could not be fetched or found, return true to allow proper handling of the route permission for the viewer */
  if (!organization || !activeOrganization)
    return "active" as OrganizationSubscriptionStanding
  return organization.subscriptionStanding
}
