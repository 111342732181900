/**
 * @generated SignedSource<<db6e9400b8679a5df3ca22f9f992088c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type webFormSubmissionsUtils_submissionUserFragment$data = {
  readonly user: {
    readonly id: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly fullName: string;
    readonly avatar: string;
  };
  readonly " $fragmentType": "webFormSubmissionsUtils_submissionUserFragment";
};
export type webFormSubmissionsUtils_submissionUserFragment$key = {
  readonly " $data"?: webFormSubmissionsUtils_submissionUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"webFormSubmissionsUtils_submissionUserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "webFormSubmissionsUtils_submissionUserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WebFormSubmission",
  "abstractKey": null
};

(node as any).hash = "dc0a39e88da06808b6cf3cac0fd3466d";

export default node;
