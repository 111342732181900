import { DiscoIcon, DiscoInput } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

type Props = TestIDProps & {
  answer: { body?: string | null }
  disabled?: boolean
}

function LinkAnswerInput(props: Props) {
  const { answer, testid = "LinkAnswerInput", disabled } = props
  const theme = useTheme()

  return (
    <DiscoInput
      padding={`0 ${theme.spacing(1.5)}px`}
      placeholder={"example.com"}
      startAdornment={<DiscoIcon icon={"link"} marginRight={1} />}
      value={answer.body || ""}
      onChange={(e) => (answer.body = e.target.value)}
      data-testid={testid}
      disabled={disabled}
    />
  )
}

export default observer(LinkAnswerInput)
