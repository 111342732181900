import { useLabel } from "@/core/context/LabelsContext"
import { WEB_FORM_RATING_SCALE } from "@/web-form/utils/webFormConstants"
import {
  WebFormQuestionInput,
  useWebFormEditorContext,
} from "@/web-form/utils/webFormEditorUtils"
import { ratingOptionDisplayNumber } from "@/web-form/utils/webFormFillerUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormLabel, DiscoInput, DiscoSelect, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { v4 as uuidv4 } from "uuid"

type Props = TestIDProps & {
  question: WebFormQuestionInput
  questionIndex: number
  readonly?: boolean
}

function WebFormEditorRatingOptions(props: Props) {
  const { question, questionIndex, testid = "WebFormEditorQuestion", readonly } = props
  const { getQuestionConfig } = useWebFormEditorContext()!
  const config = getQuestionConfig(questionIndex)
  const options = question.options!
  const classes = useStyles()
  const memberLabel = useLabel("admin_member")
  const isDisabled = config.disabled || readonly
  const labelOptionIndexes = [0, options.length - 1]

  return (
    <div className={classes.root}>
      <DiscoText
        variant={"body-sm"}
        color={"groovy.neutral.400"}
      >{`${memberLabel.plural} use the scale to rate `}</DiscoText>
      <div className={classes.scaleContainer}>
        <div>
          <div className={classes.scaleLabels}>
            {labelOptionIndexes.map((i) => (
              <DiscoText
                key={options[i].id}
                variant={"body-xs"}
                color={"groovy.neutral.400"}
              >
                {options[i].label || "Label"}
              </DiscoText>
            ))}
          </div>
          <div
            className={classNames(
              classes.scale,
              options.length > 5 ? classes.largeScale : classes.smallScale
            )}
          >
            {options.map((option, i) => (
              <div key={option.id} className={classes.option}>
                <DiscoText testid={`${testid}.option.${i}`} color={"text.secondary"}>
                  {ratingOptionDisplayNumber(i, options.length)}
                </DiscoText>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.settings}>
        <DiscoSelect
          testid={`${testid}.scale-select`}
          autoComplete={false}
          options={[
            {
              title: `1-${WEB_FORM_RATING_SCALE.SMALL}`,
              value: WEB_FORM_RATING_SCALE.SMALL,
            },
            {
              title: `1-${WEB_FORM_RATING_SCALE.LARGE}`,
              value: WEB_FORM_RATING_SCALE.LARGE,
            },
            {
              title: `0-${WEB_FORM_RATING_SCALE.LARGE_WITH_ZERO - 1}`,
              value: WEB_FORM_RATING_SCALE.LARGE_WITH_ZERO,
            },
          ]}
          value={options.length}
          onChange={handleScaleChange}
          disabled={isDisabled}
        />
        <DiscoText variant={"body-sm-600"}>{"Labels:"}</DiscoText>
        {labelOptionIndexes.map((i) => (
          <DiscoFormLabel key={options[i].id} className={classes.label}>
            <DiscoText variant={"body-sm"}>{`${ratingOptionDisplayNumber(
              i,
              options.length
            )} =`}</DiscoText>
            <DiscoInput
              data-testid={`${testid}.option.${i}.label`}
              placeholder={"Label"}
              value={options[i].label}
              onChange={(e) => (options[i].label = e.target.value)}
              disabled={isDisabled}
              className={classes.input}
            />
          </DiscoFormLabel>
        ))}
      </div>
    </div>
  )

  function handleScaleChange(scale: number | null) {
    if (!scale) return
    // Add/remove options to/from the middle since the first and last are labeled
    if (scale < options.length) {
      options.splice(1, options.length - scale)
      return
    }
    options.splice(
      1,
      0,
      ...Array.from({ length: scale - options.length }, () => ({
        id: uuidv4(),
        label: "",
      }))
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: theme.spacing(2.5),
    maxWidth: "100%",
  },
  scaleContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    alignItems: "center",
    "& > div": {
      maxWidth: "100%",
    },
  },
  scaleLabels: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5),
  },
  scale: {
    display: "flex",
    justifyContent: "center",
  },
  largeScale: {
    gap: theme.spacing(2.5),
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(0.5),
    },
  },
  smallScale: {
    gap: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(4),
    },
  },
  option: {
    height: 32,
    width: 32,
    border: `1px solid ${theme.palette.groovy.neutral[200]}`,
    borderRadius: theme.measure.borderRadius.medium,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  settings: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    flexWrap: "wrap",
  },
  label: {
    display: "flex",
    gap: theme.spacing(0.5),
    margin: 0,
  },
  input: {
    width: 140,
  },
}))

export default observer(WebFormEditorRatingOptions)
