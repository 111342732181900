import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useFormStore } from "@/core/form/store/FormStore"
import {
  GenerateAiQuizAnswersInput,
  WebFormEditorAddOptionWithAIButtonMutation,
} from "@/web-form/editor/__generated__/WebFormEditorAddOptionWithAIButtonMutation.graphql"
import {
  useWebFormEditorContext,
  WebFormQuestionInput,
} from "@/web-form/utils/webFormEditorUtils"
import EditorUtils from "@components/editor/EditorUtils"
import { DiscoIcon, DiscoTooltip } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { observer } from "mobx-react-lite"
import { graphql } from "react-relay"
import { v4 as uuidv4 } from "uuid"

interface WebFormEditorAddOptionWithAIButtonProps extends OverridableDiscoButtonProps {
  question: WebFormQuestionInput
  onClick: VoidFunction
}

function WebFormEditorAddOptionWithAIButton({
  question,
  onClick,
  testid = "WebFormEditorAddOptionWithAI",
  children,
  ...rest
}: WebFormEditorAddOptionWithAIButtonProps) {
  const isQuestionEmpty = EditorUtils.isEmpty(question.richEditorBody)
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const { template } = useWebFormEditorContext()!

  const form = useFormStore<
    WebFormEditorAddOptionWithAIButtonMutation,
    GenerateAiQuizAnswersInput
  >(
    graphql`
      mutation WebFormEditorAddOptionWithAIButtonMutation(
        $input: GenerateAiQuizAnswersInput!
      ) {
        response: generateAiQuizAnswersFromQuestion(input: $input) {
          data {
            right
            wrong
            answers
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      productId: activeProduct?.id,
      questionText: "",
      questionType: question.type,
      additionalContext: null,
      webFormTemplate: template,
    },
    { requireChangeToSubmit: false }
  )

  return (
    <DiscoTooltip
      content={
        template === "quiz"
          ? "Add a question first then use this button to generate right and wrong answers with Disco AI."
          : "Add a question first then use this button to generate options with Disco AI."
      }
      disabled={!isQuestionEmpty}
    >
      <span>
        <OverridableDiscoButton
          {...rest}
          leftIcon={<DiscoIcon icon={"sparkles"} padding={0.25} />}
          color={"grey"}
          variant={"outlined"}
          width={"100%"}
          testid={`${testid}.add-option`}
          onClick={addAIOption}
          disabled={isQuestionEmpty || form.isSubmitting}
          shouldDisplaySpinner={form.isSubmitting}
        >
          {children}
        </OverridableDiscoButton>
      </span>
    </DiscoTooltip>
  )

  async function addAIOption() {
    form.state.questionText = EditorUtils.convertToPlainText(
      JSON.parse(question.richEditorBody)
    )
    const { didSave, response } = await form.submit(form.state)
    if (!didSave) return
    if (response?.data?.answers) {
      addSurveyOptions(response.data.answers)
    } else if (response?.data?.right && response?.data?.wrong) {
      addQuizOptions(response.data.right, response.data.wrong)
    }
    onClick()
  }

  function addQuizOptions(right: readonly string[], wrong: readonly string[]) {
    const corrrectAnswerKeys: Array<string> = []
    question.options = wrong.map((option) => {
      return { id: uuidv4(), label: option }
    })
    right.forEach((option) => {
      const id = uuidv4()
      question.options!.push({ id, label: option })
      corrrectAnswerKeys.push(id)
    })
    question.options = ArrayUtils.shuffleArray(question.options)
    question.answerKey = {
      correctIds: corrrectAnswerKeys,
    }
  }

  function addSurveyOptions(options: readonly string[]) {
    question.options = options.map((option) => {
      return { id: uuidv4(), label: option }
    })
  }
}

export default observer(WebFormEditorAddOptionWithAIButton)
