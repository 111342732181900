import { useCallback, useEffect } from "react"

export const KEY_CODES = {
  ESCAPE: new Set(["Esc", "Escape"]),
  ENTER: new Set(["Enter"]),
  UP: new Set(["Up", "ArrowUp"]),
  DOWN: new Set(["Down", "ArrowDown"]),
  LEFT: new Set(["Left", "ArrowLeft"]),
  RIGHT: new Set(["Right", "ArrowRight"]),
}

export default function useOnKeydown(
  onKeydown: (e: KeyboardEvent) => void,
  deps: readonly any[]
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(onKeydown, deps)

  useEffect(() => {
    document.addEventListener("keydown", callback)
    return () => document.removeEventListener("keydown", callback)
  }, [callback])
}
