import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import { GlobalID } from "@/relay/RelayTypes"
import PollWebFormEditor from "@/web-form/editor/poll/PollWebFormEditor"
import UpdatePollSettings from "@/web-form/editor/poll/UpdatePollSettings"
import { DiscoModal, DiscoModalProps } from "@disco-ui"

type Props = Pick<DiscoModalProps, "isOpen"> & {
  onCancel: () => void
  onSave: (savedWebFormId: GlobalID) => void
  webFormId?: GlobalID
}

function PollSetupModal(props: Props) {
  const { onCancel, onSave, webFormId, ...modalProps } = props
  const unsavedChangesModal = useInitUnsavedChangesModalContext()

  return (
    <UnsavedChangesModalProvider {...unsavedChangesModal}>
      <DiscoModal
        {...modalProps}
        width={"650px"}
        testid={"PollSetupModal"}
        modalContentLabel={"Poll setup modal"}
        onClose={handleCancel}
        title={"Poll Setup"}
        body={renderBody()}
      />
    </UnsavedChangesModalProvider>
  )

  function handleCancel() {
    unsavedChangesModal.handleLeave({ onLeave: onCancel })
  }

  function renderBody() {
    // Only the settings can be changed when editing an existing poll
    if (webFormId)
      return (
        <UpdatePollSettings
          webFormId={webFormId}
          onCancel={handleCancel}
          onSave={() => onSave(webFormId)}
        />
      )
    return <PollWebFormEditor onCancel={handleCancel} onSave={onSave} />
  }
}

export default PollSetupModal
