import { DATE_FORMAT } from "@utils/time/timeConstants"
import { getLocalTimezone } from "@utils/timezone/timezoneUtils"
import { format, utcToZonedTime } from "date-fns-tz"
import { useState } from "react"

type InitialDateTimeState = {
  isoDateTime: string
  timezone: string
  duration: number
}

/**
 * given an ISO datetime { @example 2022-02-01T20:00:00.000Z }
 * optionally update date, time, duration (end time), and/or timezone
 */
function useISODateTimeTz(
  initialDateTimeState: Partial<InitialDateTimeState> | undefined = {}
) {
  const {
    isoDateTime = new Date().toISOString(),
    timezone = getLocalTimezone(),
    duration = 0,
  } = initialDateTimeState
  const [dateTimeState, _setDateTimeState] = useState<InitialDateTimeState>({
    isoDateTime,
    timezone,
    duration,
  })

  return {
    isoDateTime: dateTimeState.isoDateTime,
    timezone: dateTimeState.timezone,
    duration: dateTimeState.duration,
    /**
     * accepts a Date that will be formatted as yyyy-MM-dd
     * see {@link DATE_FORMAT.API_FORMAT}
     */
    handleDateChange,
    /** expects time arg in format 01:00:00
     * see {@link DATE_FORMAT.API_TIME_FORMAT}
     */
    handleTimeChange,
    /**
     * expects duration in minutes
     * differenceInMinutes may come in handy {@link https://date-fns.org/v2.28.0/docs/differenceInMinutes}
     */
    handleDurationChange,
    /**
     * expects timezone in format like 'America/Vancouver'
     * see {@link getLocalTimezone}
     */
    handleTimezoneChange,
    setDateTimeState,
  }

  function handleDateChange(v: Date) {
    const date = format(v, DATE_FORMAT.API_FORMAT)
    // get the iso time as Date object in timezone, and format as time incl. timezone
    const time = format(
      utcToZonedTime(dateTimeState.isoDateTime, dateTimeState.timezone),
      DATE_FORMAT.API_TIME_FORMAT
    )
    // create a new date with time and timezone
    const dateTime = format(
      new Date(`${date}T${time}`),
      DATE_FORMAT.DATETIME_API_FORMAT,
      {
        timeZone: timezone,
      }
    )
    _setDateTimeState((prev) => ({
      ...prev!,
      isoDateTime: new Date(dateTime).toISOString(),
    }))
  }

  function handleTimeChange(time: string) {
    // get the iso date as Date object in timezone, and format as pure date
    const date = format(
      utcToZonedTime(dateTimeState.isoDateTime, dateTimeState.timezone),
      DATE_FORMAT.API_FORMAT
    )
    // format the pure date and time with timezone
    const dateTime = format(
      new Date(`${date}T${time}`),
      DATE_FORMAT.DATETIME_API_FORMAT,
      {
        timeZone: timezone,
      }
    )
    _setDateTimeState((prev) => ({
      ...prev!,
      isoDateTime: new Date(dateTime).toISOString(),
    }))
  }

  function handleDurationChange(newDuration: number) {
    _setDateTimeState((prev) => ({
      ...prev!,
      duration: newDuration,
    }))
  }

  /** convert ISO datetime to new timezone offset */
  function handleTimezoneChange(newTimezone: string) {
    // get the iso datetime Date object in timezone, and format with new timezone
    const datetime = format(
      utcToZonedTime(dateTimeState.isoDateTime, dateTimeState.timezone),
      DATE_FORMAT.DATETIME_API_FORMAT,
      { timeZone: newTimezone }
    )
    _setDateTimeState((prev) => ({
      ...prev!,
      isoDateTime: new Date(datetime).toISOString(),
      timezone: newTimezone,
    }))
  }

  function setDateTimeState({
    isoDateTime: i,
    timezone: resetTz,
    duration: d = 0,
  }: Pick<InitialDateTimeState, "isoDateTime" | "timezone"> &
    Partial<Pick<InitialDateTimeState, "duration">>) {
    _setDateTimeState((prev) => ({
      ...prev,
      isoDateTime: i,
      timezone: resetTz,
      duration: d,
    }))
  }
}

export default useISODateTimeTz
