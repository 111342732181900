import { useLabel } from "@/core/context/LabelsContext"
import WebFormEditorAddOptionWithAIButton from "@/web-form/editor/WebFormEditorAddOptionWithAIButton"
import WebFormEditorQuestionOption from "@/web-form/editor/WebFormEditorQuestionOption"
import {
  useWebFormEditorContext,
  WebFormQuestionInput,
} from "@/web-form/utils/webFormEditorUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DragDrop from "@components/drag-drop/DragDrop"
import { DiscoButton, DiscoIcon, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { DropResult } from "react-beautiful-dnd"
import { v4 as uuidv4 } from "uuid"

type Props = TestIDProps & {
  question: WebFormQuestionInput
  questionIndex: number
  showAIGenerateButton?: boolean
  readonly?: boolean
}

function WebFormEditorQuestionOptions(props: Props) {
  const { question, questionIndex, testid = "WebFormEditorQuestion", readonly } = props
  const classes = useStyles()
  const inputRefs = useRef<Array<HTMLInputElement | null>>([])
  const [focusLastOption, setFocusLastOption] = useState(false)
  const options = question.options!
  const { getQuestionConfig } = useWebFormEditorContext()!
  const config = getQuestionConfig(questionIndex)
  const memberLabel = useLabel("admin_member")

  // Auto-focus the text input of a newly added option
  useEffect(() => {
    if (!focusLastOption) return
    setFocusLastOption(false)
    const el = inputRefs.current[options.length - 1]
    if (!el) return
    el.focus()
  }, [focusLastOption, options.length])

  // The AI prompt doesn't work well for ranking questions so hide the button for them
  const showAIGenerateButton = props.showAIGenerateButton && question.type !== "ranking"

  return (
    <div className={classes.root}>
      {question.type === "ranking" && (
        <DiscoText variant={"body-sm"} color={"groovy.neutral.500"} marginBottom={1.5}>
          {`Enter the options for ${memberLabel.plural} to rank below. You can then drag and reorder them according to how you want them to initially appear.`}
        </DiscoText>
      )}
      <DragDrop
        items={options.map((o) => ({ id: o.id }))}
        uniqueKey={`web-form-editor-question-${question.uniqueKey}`}
        onDragEnd={handleDragEnd}
        forwardDragHandle
      >
        {(_, __, index, ___, dragHandleProps) => (
          <WebFormEditorQuestionOption
            testid={`${testid}.option.${index}`}
            optionIndex={index}
            questionIndex={questionIndex}
            question={question}
            dragHandleProps={dragHandleProps}
            inputRef={(el) => (inputRefs.current[index] = el)}
            disabled={config.disabled || readonly}
          />
        )}
      </DragDrop>
      {!config.disabled && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={showAIGenerateButton ? 6 : 12}>
            <DiscoButton
              leftIcon={<DiscoIcon icon={"add"} padding={0.25} />}
              color={"grey"}
              variant={"dashed"}
              testid={`${testid}.add-option`}
              onClick={addOption}
              width={"100%"}
              disabled={readonly}
            >
              <DiscoText variant={"body-sm-600"}>{"Add Option"}</DiscoText>
            </DiscoButton>
          </Grid>
          {showAIGenerateButton && (
            <Grid item xs={12} sm={6}>
              <WebFormEditorAddOptionWithAIButton
                question={question}
                testid={`${testid}.add-ai-option`}
                onClick={() => setFocusLastOption(true)}
                disabled={readonly}
              >
                <DiscoText variant={"body-sm-600"}>{"Create Options with AI"}</DiscoText>
              </WebFormEditorAddOptionWithAIButton>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  )

  function addOption() {
    options.push({ id: uuidv4(), label: "" })
    setFocusLastOption(true)
  }

  function handleDragEnd({ source, destination }: DropResult) {
    if (!destination || source.index === destination.index) return
    options.splice(destination.index, 0, options.splice(source.index, 1)[0])
  }
}

const useStyles = makeUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
})

export default observer(WebFormEditorQuestionOptions)
