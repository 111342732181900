import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { WebFormQuestionInput } from "@/content-usage/__generated__/ContentModulesDragDropProvider_UpdateContentUsageMutation.graphql"
import { GenerationStatus } from "@/content/ai/GenerateContentWithAIProvider"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { useGenerateAIQuizMutation } from "@/web-form/utils/__generated__/useGenerateAIQuizMutation.graphql"
import { useCallback, useEffect } from "react"
import { graphql } from "relay-runtime"
import { v4 as uuidv4 } from "uuid"

interface Props {
  questions: WebFormQuestionInput[] | null | undefined
  onStart?: () => void
  onStatusChange?: (status: GenerationStatus) => void
}

function useGenerateAIQuiz(props: Props) {
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()

  const contentUsageDrawer = useContentUsageDrawer()

  const { questions, onStart, onStatusChange } = props
  const form = useFormStore<useGenerateAIQuizMutation>(
    graphql`
      mutation useGenerateAIQuizMutation($input: GenerateAiQuizInput!) {
        response: generateAiQuiz(input: $input) {
          data {
            type
            richEditorBody
            options {
              id
              label
            }
            answerKey {
              correctIds
            }
            isRequired
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      productId: activeProduct?.id,
      moduleContentUsageId: contentUsageDrawer.params.drawerModuleContentUsageId || "",
    }
  )

  const handleGenerateAIQuiz = useCallback(
    async () => {
      if (!form.state.moduleContentUsageId || !questions) {
        throw new Error(
          "Module content usage ID and revision are required to generate AI quiz"
        )
      }

      onStart?.()
      onStatusChange?.("loading")

      const { didSave, response } = await form.submit(form.state)

      if (!didSave || !response?.data) {
        // Remove the drawer param to prevent re-generating the quiz
        contentUsageDrawer.setParams({ drawerQuizUseAI: undefined })
        onStatusChange?.("failed")
        return
      }

      onStatusChange?.("done")
      const { data: generatedQuestions } = response
      if (!generatedQuestions.length) return

      // Set the generated questions in the revision
      for (const question of generatedQuestions) {
        questions.push({
          uniqueKey: uuidv4(),
          ...question,
        } as any)
      }

      // Remove the drawer param to prevent re-generating the quiz
      contentUsageDrawer.setParams({ drawerQuizUseAI: undefined })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form.state.moduleContentUsageId, questions]
  )

  useEffect(() => {
    if (contentUsageDrawer.params.drawerQuizUseAI !== "1" || !questions) return

    // If there are already questions, don't generate more
    if (questions.length) return

    // Trigger the AI quiz generation on mount
    handleGenerateAIQuiz()
  }, [contentUsageDrawer.params.drawerQuizUseAI, handleGenerateAIQuiz, questions])
}

export default useGenerateAIQuiz
