import {
  DECIMAL_NUMBER_SEPARATOR,
  IS_LAST_CHARACTER_DECIMAL_POINT_REGEX,
  MATCH_ZEROS_AFTER_DECIMAL_REGEX,
} from "./numberConstants"

/**
 * Coerces a number into a string.
 * @param {number} value A number to convert to string
 * @param {string} locale Default locale used is "en"
 * @return {string} The value after coercing the given value to a string.
 */
function numberToString(value: unknown, locale = "en") {
  let final = ""

  if (typeof value === "number" && !Object.is(value, NaN)) {
    final = value.toLocaleString(locale)
  } else if (typeof value === "string") {
    final = parseFloat(value).toLocaleString(locale)

    // If the value is `"[0-9]."` or `[0-9].0+"
    if (
      IS_LAST_CHARACTER_DECIMAL_POINT_REGEX.test(value) ||
      MATCH_ZEROS_AFTER_DECIMAL_REGEX.test(value)
    ) {
      const decimalNumberParts = value.split(DECIMAL_NUMBER_SEPARATOR)
      const decimalPart = decimalNumberParts[1]
      const integerPart = parseFloat(decimalNumberParts[0]).toLocaleString(locale)

      final = `${integerPart}${DECIMAL_NUMBER_SEPARATOR}${decimalPart}`
    }
  }

  return final
}

/**
 * @param {number} x number to check
 * @return {boolean} Whether x is an integer or not
 */
function isInteger(x: number): boolean {
  return String(x).split(".")[1] === undefined
}

/**
 * @param {number} limit The number of digits in decimal part of a number
 * @return {function} A function that takes in a number and returns the truncated version of it
 */
function truncateDecimalPart(limit: number) {
  return (x: number) => {
    let finalNumber = x || 0

    if (!isInteger(finalNumber)) {
      finalNumber = parseFloat(finalNumber.toFixed(limit))
    }

    return finalNumber
  }
}

/**
 * Checks if a number is either 29, 30 or 31.
 * @param {number} x
 */
function isNumber29or30or31(x: number): boolean {
  // eslint-disable-next-line no-magic-numbers
  return x === 29 || x === 30 || x === 31
}

function isNumberInRange(range: [number, number], x: number): boolean {
  return range[0] <= x && x <= range[1]
}

function getPercentage(total: number, x: number): number {
  // eslint-disable-next-line no-magic-numbers
  return (100 * x) / total
}

function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min
}

export {
  numberToString,
  truncateDecimalPart,
  isNumber29or30or31,
  isNumberInRange,
  getPercentage,
  randomInRange,
}
