import { WEB_FORM_RATING_SCALE } from "@/web-form/utils/webFormConstants"
import { ratingOptionDisplayNumber } from "@/web-form/utils/webFormFillerUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { ButtonBase, Theme, useMediaQuery } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { Fragment, useState } from "react"

type Props = TestIDProps & {
  options: readonly { id: string; label: string }[]
  answer: { selectedOptions?: string[] | null }
  disabled?: boolean
}

function RatingAnswerInput(props: Props) {
  const { options, answer, testid = "SingleSelectAnswerInput", disabled } = props
  const [hoverIndex, setHoverIndex] = useState(-1)
  const isXsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))
  const isCompact = options.length > WEB_FORM_RATING_SCALE.SMALL && isXsDown
  const classes = useStyles({ isCompact })
  const selectedIndex = answer.selectedOptions?.length
    ? options.findIndex((o) => o.id === answer.selectedOptions![0])
    : -1

  return (
    <div className={classes.container}>
      <div className={classes.labels}>
        {options.map((option) => (
          <DiscoText key={option.id} variant={"body-xs"}>
            {option.label}
          </DiscoText>
        ))}
      </div>
      <div className={classes.scale}>
        {options.map((option, i) => (
          <Fragment key={option.id}>
            {i > 0 && !isCompact && (
              <div
                className={classNames(classes.divider, {
                  [classes.selected]: i <= selectedIndex && hoverIndex < 0,
                  [classes.hovered]: hoverIndex >= i,
                })}
              />
            )}
            <ButtonBase
              data-testid={`${testid}.option.${i}`}
              disabled={disabled}
              onClick={() => {
                answer.selectedOptions = [option.id]
                setHoverIndex(-1)
              }}
              onMouseEnter={() => {
                // Only show hover styles if not hovering the current selection
                if (i === selectedIndex) return
                setHoverIndex(i)
              }}
              onMouseLeave={() => setHoverIndex(-1)}
              className={classNames(classes.circle, {
                [classes.selected]: i <= selectedIndex && hoverIndex < 0,
                [classes.hovered]: hoverIndex >= i,
              })}
            />
          </Fragment>
        ))}
      </div>
      <div className={classes.labels}>
        {options.map((option, i) => (
          <DiscoText key={option.id} color={"text.secondary"} className={classes.number}>
            {ratingOptionDisplayNumber(i, options.length)}
          </DiscoText>
        ))}
      </div>
    </div>
  )
}

type StypeProps = {
  isCompact: boolean
}

const BUTTON_WIDTH = 32
const BUTTON_WIDTH_COMPACT = 24

const useStyles = makeUseStyles((theme) => ({
  container: {
    width: "100%",
  },
  labels: {
    display: "flex",
    justifyContent: "space-between",
  },
  scale: ({ isCompact }: StypeProps) => ({
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(isCompact ? 0.5 : 1),
    alignItems: "center",
    margin: theme.spacing(0.5, 0),
    overflowX: "auto",
  }),
  divider: {
    borderBottom: `1.5px solid ${theme.palette.groovy.neutral[200]}`,
    flexGrow: 1,
  },
  circle: ({ isCompact }: StypeProps) => ({
    border: `1.5px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: "50%",
    height: isCompact ? BUTTON_WIDTH_COMPACT : BUTTON_WIDTH,
    width: isCompact ? BUTTON_WIDTH_COMPACT : BUTTON_WIDTH,
  }),
  number: ({ isCompact }: StypeProps) => ({
    // Numbers need same width as the buttons so they show centered underneath them
    width: isCompact ? BUTTON_WIDTH_COMPACT : BUTTON_WIDTH,
    textAlign: "center",
  }),
  selected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  },
  hovered: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
}))

export default observer(RatingAnswerInput)
