import { formatDistanceToNowStrict, isAfter, isBefore, isSameDay } from "date-fns"
import timezonedFormat from "date-fns-tz/format"
import utcToZonedTime from "date-fns-tz/utcToZonedTime"
import add from "date-fns/add"
import differenceInDays from "date-fns/differenceInDays"
import differenceInHours from "date-fns/differenceInHours"
import differenceInMinutes from "date-fns/differenceInMinutes"
import differenceInWeeks from "date-fns/differenceInWeeks"
import formatWithOptions from "date-fns/fp/formatWithOptions"
import isFuture from "date-fns/isFuture"
import isPast from "date-fns/isPast"
import isToday from "date-fns/isToday"
import isTomorrow from "date-fns/isTomorrow"
import enCA from "date-fns/locale/en-CA"
import pluralize from "pluralize"
import { useEffect, useState } from "react"
import { ApiRangeModel } from "../../core/network-manager/networkModels"
import {
  DATE_FORMAT,
  DAY_IN_HRS,
  HOUR_IN_MINS,
  HOUR_IN_S,
  MINUTE_IN_MS,
  MINUTE_IN_S,
} from "./timeConstants"
import { FormatDateUtilOptions } from "./timeTypes"

/**
 * Formats a Date object into a human friendly string
 *
 * Note on usage:
 *    Whenever the timezone information is not available and a date object that is passed to this function is generated from a ISO-8601 date string (yyyy-MM-dd), `shouldShiftDateToCompensateForTimezone` option should be set to `true`.
 *    Otherwise, the displayed date would be inconsistent with the data. For example, for a user with Eastern Daylight Time (to generalize, any user that has a negative UTC offset), the following Date object is created for "2007-05-16": Tue May 15 2007 20:00:00 GMT-0400 (Eastern Daylight Time) {}. Therefore, 15 May appears on the screen. By passing `new Date("2007-05-16")` value to `compensateForTimezone` utility, we fix this problem.
 *    When the timezone information is passed to `formatDateWithOptions`, this extra compensation is redundant as `date-fns-tz/utcToZonedTime` handles it corrently.
 *
 * @param {object} options FormatDateUtilOptions
 * @return {string} Formatted date
 */
function formatDateWithOptions(options: FormatDateUtilOptions = {}) {
  const {
    format = DATE_FORMAT.DEFAULT,
    shouldShiftDateToCompensateForTimezone = true,
    isProvidedDateInUTC = true,
    timeZone,
  } = options

  return (date: Date): string => {
    let dateToFormat = date

    if (timeZone && isProvidedDateInUTC) {
      dateToFormat = utcToZonedTime(date, timeZone)
    } else if (!timeZone && shouldShiftDateToCompensateForTimezone) {
      dateToFormat = compensateForTimeZone(date)
    }

    try {
      return timeZone
        ? timezonedFormat(dateToFormat, format, {
            locale: enCA,
            timeZone,
          })
        : formatWithOptions({ locale: enCA }, format)(dateToFormat)
    } catch (err) {
      throw new Error(
        `Error in formatDateWithOptions for ${JSON.stringify({
          format,
          timeZone,
          dateToFormat,
          date,
        })}: ${err}`
      )
    }
  }
}

/**
 * Shifts the time of the Date object by the system's UTC offset to avoid timezone issues.
 *
 * For example, for a user with Eastern Daylight Time (to generalize, any user that has a negative UTC offset), the following Date object is created for "2007-05-16": Tue May 15 2007 20:00:00 GMT-0400 (Eastern Daylight Time) {}.
 * By using this utility we get the following Date object: `compensateForTimeZone(new Date("2007-05-16"))` = Tue May 16 2007 00:00:00 GMT-0400 (Eastern Daylight Time) {}
 *
 * @param {Date} date Date to shift
 * @return {Date} shifted Date
 */
function compensateForTimeZone(date: Date): Date {
  // `Date.prototype.getTimezoneOffset` returns a value signed according to the locale timezone offset. See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
  return new Date(date.getTime() - date.getTimezoneOffset() * -1 * MINUTE_IN_MS)
}

function isDateInRange(dateRange: ApiRangeModel<Date>) {
  return isPast(dateRange.lower) && isFuture(dateRange.upper)
}

function getDifferenceInMinutes(timeZone: string, date: Date): number {
  const zonedDateTime = utcToZonedTime(date, timeZone)
  const zonedNow = utcToZonedTime(getNowInUTC(), timeZone)

  return differenceInMinutes(zonedDateTime, zonedNow)
}

function getDifferenceInHours(timeZone: string, date: Date): number {
  const zonedDateTime = utcToZonedTime(date, timeZone)
  const zonedNow = utcToZonedTime(getNowInUTC(), timeZone)

  return differenceInHours(zonedDateTime, zonedNow)
}

function getDifferenceInDays(timeZone: string, date: Date): number {
  const zonedDateTime = utcToZonedTime(date, timeZone)
  const zonedNow = utcToZonedTime(getNowInUTC(), timeZone)

  return differenceInDays(zonedDateTime, zonedNow)
}

// Get the strict day difference between the source product start date and the new start date (yyyy-MM-dd)
// This util shifts both dates by the UTC offset before passing them to differenceInDays.
// Otherwise, daylight saving can mess up the calculation since differenceInDays counts the full days.
function getDifferenceInDaysBetweenDates(
  leftDateString: string,
  rightDateString: string
): number {
  return differenceInDays(
    compensateForTimeZone(new Date(leftDateString)),
    compensateForTimeZone(new Date(rightDateString))
  )
}

function getDifferenceInWeeks(timeZone: string, date: Date): number {
  const zonedDateTime = utcToZonedTime(date, timeZone)
  const zonedNow = utcToZonedTime(getNowInUTC(), timeZone)

  return differenceInWeeks(zonedDateTime, zonedNow)
}

function formatMinutesInHHMM(minutes: number) {
  const hourLeft = `${Math.floor(minutes / HOUR_IN_MINS)}`
  const minLeft = `${minutes % HOUR_IN_MINS}`

  // eslint-disable-next-line no-magic-numbers
  return `${hourLeft.padStart(2, "0")}:${minLeft.padStart(2, "0")}`
}

function isDateTimeInPast(date: undefined | null | string | Date): boolean {
  if (!date) return true
  return isPast(typeof date === "string" ? new Date(date) : date)
}

function isDateTimeInTheFuture(date: undefined | null | string | Date) {
  if (!date) return true
  return isFuture(typeof date === "string" ? new Date(date) : date)
}

function isTodayOrTomorrow(
  timeZone: string,
  date: undefined | null | string | Date
): boolean {
  let todayOrTomorrow = true

  if (date) {
    let dateToCheck = date

    if (typeof date === "string") {
      dateToCheck = new Date(date)
    }

    const zonedDate = utcToZonedTime(dateToCheck, timeZone)

    todayOrTomorrow = isToday(zonedDate) || isTomorrow(zonedDate)
  }

  return todayOrTomorrow
}

function isSameDayOrAfter(timeZone: string, date: Date, referenceDate: Date): boolean {
  const zonedDate = utcToZonedTime(date, timeZone)
  const zonedReferenceDate = utcToZonedTime(referenceDate, timeZone)

  return (
    isSameDay(zonedDate, zonedReferenceDate) || isAfter(zonedDate, zonedReferenceDate)
  )
}

function isSameDayOrBefore(timeZone: string, date: Date, referenceDate: Date): boolean {
  const zonedDate = utcToZonedTime(date, timeZone)
  const zonedReferenceDate = utcToZonedTime(referenceDate, timeZone)

  return (
    isSameDay(zonedDate, zonedReferenceDate) || isBefore(zonedDate, zonedReferenceDate)
  )
}

/**
 * Returns a natural language formating of a provided date.
 * eg. today, tomorrow or November 5
 * @param timeZone The timezone in the format Area/Location to use as reference
 * @param date The date to format as string
 * @param dateFormat an optional formatting string eg. MMM d, yyyy
 */

function getDifferenceToNowInWords(
  {
    timeZone,
    dateFormat = DATE_FORMAT.DEFAULT,
    includeTimeForTodayOrTomorrow,
  }: { timeZone: string; dateFormat?: string; includeTimeForTodayOrTomorrow?: boolean },
  date: undefined | null | string | Date
): string {
  let text = ""

  if (date) {
    let dateToCheck = date

    if (typeof date === "string") {
      dateToCheck = new Date(date)
    }

    const zonedDate = utcToZonedTime(dateToCheck, timeZone)
    const isDateToday = isToday(zonedDate)
    const isDateTomorrow = isTomorrow(zonedDate)

    if (isDateToday) {
      text = "Today"
    } else if (isDateTomorrow) {
      text = "Tomorrow"
    } else {
      text = timezonedFormat(zonedDate, dateFormat, {
        locale: enCA,
        timeZone,
      })
    }

    if (includeTimeForTodayOrTomorrow && (isDateToday || isDateTomorrow)) {
      text += `, ${timezonedFormat(zonedDate, DATE_FORMAT.SHORT_TIME_FORMAT, {
        locale: enCA,
        timeZone,
      })}`
    }
  }

  return text
}

function getNowInUTC() {
  return new Date().toISOString()
}

function useClock(interval: number) {
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    const intervalId = setInterval(() => setNow(new Date()), interval)
    return () => clearInterval(intervalId)
  }, [interval, setNow])

  return now
}

/**
 * Formats the duration of a content in seconds to a string hh:mm:ss or mm:ss,
 * for content > 3600 (1 hour), format as hh:mm:ss, else mm:ss
 *
 * @param {number} seconds Duration in seconds to be formatted
 * @return {string} formatted duration as hh:mm:ss or mm:ss
 */
function formatDurationSeconds(seconds: number) {
  const hourLeft = `${Math.floor(seconds / HOUR_IN_S)}`
  const minLeft = `${Math.floor((seconds % HOUR_IN_S) / HOUR_IN_MINS)}`
  const secLeft = `${seconds % MINUTE_IN_S}`

  // If longer than an hour, display hh:mm:ss, else mm:ss
  const duration =
    seconds >= HOUR_IN_S
      ? `${hourLeft}:${minLeft.padStart(2, "0")}:${secLeft.padStart(2, "0")}`
      : `${minLeft}:${secLeft.padStart(2, "0")}`

  return duration
}

/**
 * Formats the time in seconds to a string with units starting with seconds and
 * increasing up to days if necessary. Ex: "15s", "22m8s", "3d18h45m9s"
 */
function formatElapsedTime(seconds: number, options?: { precision?: number }) {
  let formatted = ""
  let s = seconds
  if (s > MINUTE_IN_S) {
    let m = Math.floor(s / MINUTE_IN_S)
    s = s % MINUTE_IN_S
    if (m > HOUR_IN_MINS) {
      let h = Math.floor(m / HOUR_IN_MINS)
      m = m % HOUR_IN_MINS
      if (h > DAY_IN_HRS) {
        const d = Math.floor(h / DAY_IN_HRS)
        h = h % DAY_IN_HRS
        formatted += `${d}d`
      }
      formatted += `${h}h`
    }
    formatted += `${m}m`
  }
  formatted += `${s}s`

  // Trim to the provided precision if necessary
  // ie. if precision is 3, "1d2h3m4s" will be formatted as "1d2h3m"
  // ie. if precision is 2, "1d2h3m4s" will be formatted as "1d2h"
  // ie. if precision is 1, "1d2h3m4s" will be formatted as "1d"
  if (options?.precision) {
    const parts = formatted.split(/(\d+[a-z])/).filter(Boolean)
    formatted = parts.slice(0, options.precision).join("")
  }

  return formatted
}

type FormatDistanceToNowStrictOptions = {
  addSuffix?: boolean | undefined
  unit?: "second" | "minute" | "hour" | "day" | "month" | "year" | undefined
  roundingMethod?: "floor" | "ceil" | "round" | undefined
  locale?: Locale | undefined
}

/**
 * Works exactly like the formatDistanceToNowStrict but replaces 0-60 seconds with 'just now'
 * Examples: "just now" / "6 months" / "15 seconds" / "1 year"
 * By default, future times will be prefixed with 'in' eg. "in 6 months" / "in 15 seconds" / "in 1 year"
 * @param {Date} date the date to be compared with the current time
 * @param {FormatDistanceToNowStrictOptions} options extra options for formatDistanceToNowStrict
 */
export function getTimeDifferenceAsText(
  date: Date,
  options?: FormatDistanceToNowStrictOptions
) {
  const now = new Date()
  const diffInSeconds = Math.abs((now.getTime() - date.getTime()) / 1000)

  if (diffInSeconds < 60) return "just now"
  return formatDistanceToNowStrict(date, { addSuffix: true, ...options })
}

export function parseGolangDuration(duration: string): { unit: string; amount: number } {
  const unit = duration.slice(-1)
  const parsedAmount = parseInt(duration.slice(0, -1))
  const amount = isNaN(parsedAmount) ? 0 : parsedAmount
  return { unit, amount }
}

export function displayGolangDuration(duration: string): string {
  const { unit, amount } = parseGolangDuration(duration)
  const unitLabel = GOLANG_DURATION_UNIT_LABELS[unit]
  return `${amount} ${unitLabel ? pluralize(unitLabel, amount) : unit}`
}

/**
 * The 'd' (day) unit isn't supported in go, so convert to hours for saving
 */
export function convertGolangDurationFromDays(duration: string): string {
  const { amount, unit } = parseGolangDuration(duration)
  if (unit === "d") return `${amount * 24}h`
  return duration
}

/**
 * Convert exact 24 hour intervals into day units in a golang duration
 */
export function convertGolangDurationToDays(duration: string): string {
  const { amount, unit } = parseGolangDuration(duration)
  if (unit === "h" && amount && amount % 24 === 0) return `${amount / 24}d`
  return duration
}

/** Parse go duration string to seconds */
export function golangDurationToSeconds(duration: string): number {
  const unit = duration.slice(-1)
  const amount = parseInt(duration.slice(0, -1))
  if (isNaN(amount)) return 0
  switch (unit) {
    case "s":
      return amount
    case "m":
      return amount * 60
    case "h":
      return amount * 60 * 60
    case "d":
      return amount * 60 * 60 * 24
    default:
      throw new Error(`Invalid duration ${duration}`)
  }
}

export function subtractGolangDuration(date: Date, duration: string): Date {
  return add(date, { seconds: -golangDurationToSeconds(duration) })
}

export const GOLANG_DURATION_UNIT_LABELS: Record<string, string> = {
  m: "Minute",
  h: "Hour",
  d: "Day",
}

export {
  compensateForTimeZone,
  formatDateWithOptions,
  formatDurationSeconds,
  formatElapsedTime,
  formatMinutesInHHMM,
  getDifferenceInDays,
  getDifferenceInDaysBetweenDates,
  getDifferenceInHours,
  getDifferenceInMinutes,
  getDifferenceInWeeks,
  getDifferenceToNowInWords,
  isDateInRange,
  isDateTimeInPast,
  isDateTimeInTheFuture,
  isSameDayOrAfter,
  isSameDayOrBefore,
  isTodayOrTomorrow,
  useClock,
}
