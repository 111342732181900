import { useCallback, useState } from "react"

export default function useDisclosure(initialValue?: boolean | (() => boolean)) {
  const [isOpen, setIsOpen] = useState<boolean>(
    typeof initialValue === "function" ? initialValue : Boolean(initialValue)
  )

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onToggle = useCallback(() => {
    setIsOpen((v) => !v)
  }, [])

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  }
}
