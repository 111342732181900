import ReorderIcon from "@/core/ui/iconsax/linear/custom-dots-tune.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DragDrop from "@components/drag-drop/DragDrop"
import { DiscoSelect, DiscoText } from "@disco-ui"
import { range } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useMemo } from "react"
import { DropResult } from "react-beautiful-dnd"

type Props = TestIDProps & {
  options: readonly { id: string; label: string }[]
  answer: { selectedOptions?: string[] | null }
  disabled?: boolean
}

function RankingAnswerInput(props: Props) {
  const { options, answer, testid = "RankingAnswerInput", disabled } = props
  const classes = useStyles({ disabled })
  const rankSelectOptions = useMemo(
    () => range(options.length).map((i) => ({ value: i, title: `${i + 1}` })),
    [options.length]
  )

  // selectedOptions doesn't get populated until the user modifies the order so that the
  // question can be skipped if they don't want to answer
  const hasAnswer = Boolean(answer.selectedOptions?.length)
  const selectedOptions = hasAnswer ? answer.selectedOptions! : options.map((o) => o.id)

  return (
    <DragDrop
      uniqueKey={testid}
      items={selectedOptions.map((id) => ({ id }))}
      isDragDisabled={disabled}
      onDragEnd={handleDragEnd}
      classes={{ list: classes.list }}
    >
      {(item, _, i) => (
        <div
          key={item.id}
          data-testid={`${testid}.option.${i}`}
          className={classes.option}
        >
          <div className={classes.dragHandle}>
            <ReorderIcon />
          </div>
          <DiscoSelect
            testid={`${testid}.option.${i}.select-rank`}
            autoComplete={false}
            options={rankSelectOptions}
            value={hasAnswer ? i : null}
            onChange={(v) => handleRankChange(i, v)}
            disabled={disabled}
          />
          <div className={classes.label}>
            <DiscoText testid={`${testid}.option.${i}.label`} variant={"body-sm"}>
              {options.find((o) => o.id === item.id)!.label}
            </DiscoText>
          </div>
        </div>
      )}
    </DragDrop>
  )

  function handleDragEnd({ source, destination }: DropResult) {
    if (!destination) return
    if (!hasAnswer) answer.selectedOptions = options.map((o) => o.id)
    const [option] = answer.selectedOptions!.splice(source.index, 1)
    answer.selectedOptions!.splice(destination.index, 0, option)
  }

  function handleRankChange(fromIndex: number, toIndex: number | null) {
    if (toIndex === null) return
    if (!hasAnswer) answer.selectedOptions = options.map((o) => o.id)
    const [option] = answer.selectedOptions!.splice(fromIndex, 1)
    answer.selectedOptions!.splice(toIndex, 0, option)
  }
}

type StyleProps = {
  disabled?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
  },
  option: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    cursor: "grab",
  },
  dragHandle: ({ disabled }: StyleProps) => ({
    display: disabled ? "none" : "flex",
    "& svg": {
      height: "32px",
      color: theme.palette.groovy.grey[theme.palette.type === "dark" ? 300 : 400],
    },
  }),
  label: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.medium,
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}))

export default observer(RankingAnswerInput)
