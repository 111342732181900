import { isE2ETest } from "@utils/e2e"
import { useFlags as useLDFlags } from "launchdarkly-react-client-sdk"
import React, { createContext, useContext, useEffect, useState } from "react"

export type FeatureFlags = Record<string, any>

/** Get the active feature flags */
const useFeatureFlags: () => FeatureFlags = isE2ETest() ? useE2EFlags : useLDFlags
export default useFeatureFlags

const E2EMockFlagsContext = createContext<FeatureFlags>({})

/** Sets a setE2EMockFlags to the window so that e2e tests can update the mocked out flags */
export const E2EMockFlagsProvider: React.FC = ({ children }) => {
  const [flags, setFlags] = useState<FeatureFlags>({})

  useEffect(() => {
    window.setE2EMockFlags = setFlags
  }, [setFlags])

  return (
    <E2EMockFlagsContext.Provider value={flags}>{children}</E2EMockFlagsContext.Provider>
  )
}

function useE2EFlags(): FeatureFlags {
  return useContext(E2EMockFlagsContext)
}

declare global {
  // eslint-disable-next-line no-var, vars-on-top, no-inner-declarations
  var setE2EMockFlags: (flags: FeatureFlags) => void
}
