import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import WebFormSettings from "@/web-form/editor/WebFormSettings"
import { UpdatePollSettingsMutation } from "@/web-form/editor/poll/__generated__/UpdatePollSettingsMutation.graphql"
import { UpdatePollSettingsQuery } from "@/web-form/editor/poll/__generated__/UpdatePollSettingsQuery.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoButtonSkeleton, DiscoCheckboxSkeleton } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

type Props = TestIDProps & {
  webFormId: GlobalID
  onCancel: () => void
  onSave: () => void
}

function UpdatePollSettings(props: Props) {
  const { webFormId, onCancel, onSave, testid = "UpdatePollSettings" } = props
  const unsavedChangesModal = useUnsavedChangesModalContext()
  const classes = useStyles()

  const { webForm } = useLazyLoadQuery<UpdatePollSettingsQuery>(
    graphql`
      query UpdatePollSettingsQuery($id: ID!) {
        webForm: node(id: $id) {
          ... on WebForm {
            ...PollWebForm_settingsFragment @relay(mask: false)
          }
        }
      }
    `,
    { id: webFormId }
  )

  const form = useFormStore<UpdatePollSettingsMutation>(
    graphql`
      mutation UpdatePollSettingsMutation($input: UpdateWebFormInput!) {
        response: updateWebForm(input: $input) {
          node {
            ...PollWebForm_settingsFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    { id: webFormId, settings: webForm?.settings }
  )

  useEffect(() => {
    unsavedChangesModal.setUnsavedChanges(form.isChanged)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.isChanged])

  return (
    <Form onSubmit={handleSubmit}>
      <WebFormSettings testid={testid} form={form} />
      <div className={classes.footer}>
        <DiscoButton
          testid={`${testid}.cancel`}
          variant={"outlined"}
          color={"grey"}
          onClick={onCancel}
        >
          {"Cancel"}
        </DiscoButton>
        <Form.SubmitButton testid={`${testid}.save`} form={form}>
          {"Save"}
        </Form.SubmitButton>
      </div>
    </Form>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit(form.state)
    if (!didSave) return
    displaySuccessToast({
      message: "Poll settings saved!",
      testid: `${testid}.success`,
    })
    onSave()
  }
}

const useStyles = makeUseStyles((theme) => ({
  footer: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
}))

function UpdatePollSettingsSkeleton() {
  const classes = useStyles()

  return (
    <>
      <DiscoCheckboxSkeleton />
      <DiscoCheckboxSkeleton />
      <div className={classes.footer}>
        <DiscoButtonSkeleton width={"100px"} />
        <DiscoButtonSkeleton width={"100px"} />
      </div>
    </>
  )
}

export default Relay.withSkeleton({
  component: observer(UpdatePollSettings),
  skeleton: UpdatePollSettingsSkeleton,
})
