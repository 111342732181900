import { DiscoInput } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

type Props = TestIDProps & {
  answer: { body?: string | null }
  disabled?: boolean
}

function WrittenAnswerInput(props: Props) {
  const { answer, testid = "WrittenAnswerInput", disabled } = props
  const theme = useTheme()

  return (
    <DiscoInput
      multiline
      padding={`${theme.spacing(1.5)}px`}
      minHeight={"120px"}
      placeholder={"Click to start typing"}
      value={answer.body || ""}
      onChange={(e) => (answer.body = e.target.value)}
      data-testid={testid}
      disabled={disabled}
    />
  )
}

export default observer(WrittenAnswerInput)
