import ReorderIcon from "@/core/ui/iconsax/linear/custom-dots-tune.svg"
import {
  useWebFormEditorContext,
  WebFormQuestionInput,
} from "@/web-form/utils/webFormEditorUtils"
import { indexToLetter } from "@/web-form/utils/webFormFillerUtils"
import MultiChecked from "@assets/iconsax/bold/tick-square.svg"
import MultiUnchecked from "@assets/iconsax/linear/stop.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import {
  DiscoCheckbox,
  DiscoFormControl,
  DiscoIcon,
  DiscoIconButton,
  DiscoInput,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import { FormHelperText, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import React, { useMemo } from "react"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"

type Props = TestIDProps & {
  optionIndex: number
  questionIndex: number
  question: WebFormQuestionInput
  dragHandleProps?: DraggableProvidedDragHandleProps
  inputRef: React.Ref<HTMLInputElement>
  disabled?: boolean
}

function WebFormEditorQuestionOption(props: Props) {
  const {
    testid = "WebFormEditorQuestionOption",
    optionIndex,
    questionIndex,
    question,
    dragHandleProps,
    inputRef,
    disabled,
  } = props
  const classes = useStyles({ disabled })
  const theme = useTheme()
  const { form, template } = useWebFormEditorContext()!
  const isQuiz = template === "quiz"
  const options = question.options!
  const option = options[optionIndex]
  const errors = form.errorsByField[`questions.${questionIndex}.options.${optionIndex}`]
  const hasErrors = Boolean(errors?.length)
  const isChecked = (question.answerKey?.correctIds || []).includes(option.id)
  const showOnHoverClasses = useShowOnHoverStyles()

  const customIcon = useMemo(
    () =>
      question.type === "multiple_select" ? (
        <MultiUnchecked
          color={theme.palette.groovy[hasErrors ? "red" : "neutral"][400]}
          height={24}
          width={24}
        />
      ) : (
        <DiscoIcon
          icon={"check-circle"}
          color={theme.palette.groovy[hasErrors ? "red" : "neutral"][400]}
        />
      ),
    [question.type, hasErrors, theme.palette.groovy]
  )
  const checkedIcon = useMemo(
    () =>
      question.type === "multiple_select" ? (
        <MultiChecked
          color={theme.palette.groovy[hasErrors ? "red" : "green"][600]}
          height={24}
          width={24}
        />
      ) : (
        <DiscoIcon
          icon={"check-circle"}
          active
          color={theme.palette.groovy[hasErrors ? "red" : "green"][600]}
        />
      ),
    [question.type, hasErrors, theme.palette.groovy]
  )

  return (
    <div className={classNames(classes.option, showOnHoverClasses.hoverable)}>
      <div {...dragHandleProps} className={classes.dragHandle}>
        <ReorderIcon
          className={classNames(classes.reorderIcon, showOnHoverClasses.showable)}
        />
      </div>
      {isQuiz && (
        <DiscoTooltip
          content={`${isChecked ? "Marked" : "Mark"} as correct answer`}
          disabled={disabled && !isChecked}
        >
          <div>
            <DiscoCheckbox
              testid={`${testid}.checkbox`}
              customIcon={customIcon}
              customCheckedIcon={checkedIcon}
              value={option.id}
              onChange={handleAnswerKey}
              checked={isChecked}
              label={""}
              disabled={disabled}
              classes={{ root: classes.checkboxControl }}
            />
          </div>
        </DiscoTooltip>
      )}
      {question.type === "ranking" && (
        <div className={classes.orderNumber}>
          <DiscoText
            testid={`${testid}.order-number`}
            variant={"body-sm"}
            color={
              theme.palette.type === "dark" ? "groovy.neutral.500" : "groovy.neutral.400"
            }
          >
            {optionIndex + 1}
          </DiscoText>
        </div>
      )}
      <DiscoFormControl error={Boolean(errors)} errorMessages={errors} marginBottom={0}>
        <DiscoInput
          data-testid={`${testid}.label`}
          inputRef={inputRef}
          fullWidth
          value={option.label}
          onChange={(e) => (option.label = e.target.value)}
          placeholder={`Option ${indexToLetter(optionIndex)}`}
          disabled={disabled}
          aria-describedby={`${option.id}.correct-answer-text`}
          className={classNames({
            [classes.correctAnswerLabelInput]: isChecked && !errors?.length,
          })}
        />
        {isChecked && !errors?.length && (
          <FormHelperText
            id={`${option.id}.correct-answer-text`}
            classes={{ root: classes.correctAnswerText }}
          >
            {"This answer is correct"}
          </FormHelperText>
        )}
      </DiscoFormControl>
      {options.length > 1 && !disabled && (
        <DiscoIconButton
          testid={`${testid}.delete`}
          className={classes.deleteButton}
          onClick={handleDelete}
        >
          <DiscoIcon icon={"close"} />
        </DiscoIconButton>
      )}
    </div>
  )

  function handleDelete() {
    const deleted = options.splice(optionIndex, 1)[0]
    const { answerKey } = question || {}
    if (!answerKey?.correctIds?.length) return
    answerKey.correctIds = answerKey.correctIds.filter((id) => id !== deleted.id)
  }

  function handleAnswerKey(checked: boolean) {
    switch (question.type) {
      case "single_select": {
        if (checked) question.answerKey = { correctIds: [option.id] }
        break
      }
      case "multiple_select": {
        if (checked) {
          question.answerKey = {
            correctIds: [...(question.answerKey?.correctIds || []), option.id],
          }
        } else {
          question.answerKey = {
            correctIds: (question.answerKey?.correctIds || []).filter(
              (id) => id !== option.id
            ),
          }
        }
        break
      }
    }
  }
}

type StyleProps = {
  disabled?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  option: {
    marginBottom: theme.spacing(1.5),
    display: "flex",
    gap: theme.spacing(0.5),
  },
  dragHandle: ({ disabled }: StyleProps) => ({
    display: disabled ? "none" : "flex",
    paddingTop: theme.spacing(0.5),
    "& svg": {
      height: "32px",
      color: theme.palette.groovy.grey[theme.palette.type === "dark" ? 300 : 400],
    },
  }),
  reorderIcon: {
    padding: theme.spacing(1),
  },
  deleteButton: {
    padding: 0,
    height: "40px",
    width: "40px",
  },
  checkboxControl: {
    marginRight: 0,
    alignItems: "flex-start",
  },
  correctAnswerText: {
    color: theme.palette.groovy.green[600],
  },
  correctAnswerLabelInput: {
    "&:not(:focus)": {
      backgroundColor:
        theme.palette.groovy.green[theme.palette.type === "dark" ? 700 : 100],
      borderColor: theme.palette.groovy.green[600],
    },
  },
  orderNumber: {
    border: `1px solid ${
      theme.palette.groovy.neutral[theme.palette.type === "dark" ? 400 : 300]
    }`,
    borderRadius: theme.measure.borderRadius.default,
    height: "24px",
    width: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    marginTop: theme.spacing(1),
  },
}))

export default observer(WebFormEditorQuestionOption)
