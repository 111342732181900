import { DiscoChip } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"

interface WebFormResultChipProps extends TestIDProps {
  isCorrectOption: boolean
  isSelected: boolean
}

function WebFormResultChip({
  testid = "ResultChip",
  isCorrectOption,
  isSelected,
}: WebFormResultChipProps) {
  const shouldShowChip = isCorrectOption || isSelected
  const theme = useTheme()
  if (!shouldShowChip) return null
  return (
    <DiscoChip
      testid={testid}
      customTextColor={theme.palette.common.white}
      customBackgroundColor={
        theme.palette.groovy[
          isCorrectOption && isSelected ? "green" : isCorrectOption ? "neutral" : "red"
        ][isCorrectOption && !isSelected ? 300 : 600]
      }
      label={isCorrectOption ? "Correct" : "Incorrect"}
    />
  )
}

export default WebFormResultChip
