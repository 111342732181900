import { WebFormQuestionType } from "@/web-form/utils/__generated__/webFormEditorUtils_getFormStateFromRevisionFragment.graphql"
import { useWebFormQuestionTypes } from "@/web-form/utils/useWebFormQuestionTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSelect, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

type Props = TestIDProps & {
  value: WebFormQuestionType
  onSelect: (type: WebFormQuestionType) => void
  allowedTypes?: WebFormQuestionType[]
}

export default function WebFormQuestionTypeSelect(props: Props) {
  const { testid = "WebFormQuestionTypeDropdown", value, onSelect, allowedTypes } = props
  const classes = useStyles()
  let options = useWebFormQuestionTypes()
  if (allowedTypes) options = options.filter((o) => allowedTypes.includes(o.value))

  return (
    <DiscoSelect
      testid={testid}
      options={options.map((option) => ({
        title: option.title,
        subtitle: option.subtitle,
        value: option.value,
        icon: option.icon,
      }))}
      renderOption={(option) => (
        <div className={classes.option}>
          {option.icon}
          <div>
            <DiscoText variant={"body-sm-500"}>{option.title}</DiscoText>
            <DiscoText variant={"body-sm"} color={"text.secondary"} marginTop={0.5}>
              {option.subtitle}
            </DiscoText>
          </div>
        </div>
      )}
      value={value}
      renderValue={(option) => (
        <div className={classes.option}>
          {option.icon}
          <DiscoText variant={"body-sm-500"}>{option.title}</DiscoText>
        </div>
      )}
      onChange={(v) => {
        if (!v) return
        onSelect(v)
      }}
      autoComplete={false}
      customSelectProps={{
        MenuProps: {
          classes: {
            paper: classes.menu,
          },
        },
      }}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  menu: {
    // Prevents option subtitles getting too squished in dropdown
    minWidth: "320px !important",
  },
  option: {
    display: "flex",
    gap: theme.spacing(1),
  },
}))
