import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Relay from "@/relay/relayUtils"
import { useRefetchableZoomMeetingProvidersQuery } from "@/zoom/util/hook/__generated__/useRefetchableZoomMeetingProvidersQuery.graphql"
import { graphql, useSubscribeToInvalidationState } from "react-relay"

function useRefetchableZoomMeetingProviders() {
  const activeOrganization = useActiveOrganization()!
  const [{ organization }, refetch] =
    Relay.useRefetchableQuery<useRefetchableZoomMeetingProvidersQuery>(
      graphql`
        query useRefetchableZoomMeetingProvidersQuery($id: ID!) {
          organization: node(id: $id) {
            ... on Organization {
              id
              meetingProviders(platform: zoom) {
                edges {
                  node {
                    ...OrganizationZoomConnectionListItemFragment
                    ...OrganizationZoomConnectionListItem_meetingProviderFragment
                      @relay(mask: false)
                  }
                }
              }
            }
          }
        }
      `,
      {
        id: activeOrganization.id,
      },
      {
        refetchInBackground: true,
      }
    )

  useSubscribeToInvalidationState(
    [`client:${activeOrganization.id}:meetingProviders(platform:"zoom")`],
    () => {
      refetch({
        id: activeOrganization.id,
      })
    }
  )

  const zoomProviders = Relay.connectionToArray(organization?.meetingProviders)

  return { zoomProviders }
}

export default useRefetchableZoomMeetingProviders
