import { WebViewMessage, WebViewMessageType } from "@/apps/util/hooks/useWebViewMessage"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { useEffect } from "react"
import { generatePath } from "react-router-dom"

export function isWebView() {
  const isPhone = document.querySelector('meta[name="phone"]')
  if (isPhone) return true

  const isTablet = document.querySelector('meta[name="tablet"]')
  if (isTablet) return true

  return false
}

export function getDevice() {
  if (!isWebView()) return "browser"

  const isPhone = document.querySelector('meta[name="phone"]')
  if (isPhone) return "phone"

  const isTablet = document.querySelector('meta[name="tablet"]')
  if (isTablet) return "tablet"

  return "browser"
}

export type WebViewContentDrawers = "bookmarks" | "profileSettings"

const COMMUNITY_WEBVIEW_PATH_MAP: Record<WebViewContentDrawers, string> = {
  bookmarks: ROUTE_NAMES.COMMUNITY.WEBVIEW.BOOKMARKS,
  profileSettings: ROUTE_NAMES.COMMUNITY.WEBVIEW.PROFILE,
}

const PRODUCT_WEBVIEW_PATH_MAP: Record<WebViewContentDrawers, string> = {
  bookmarks: ROUTE_NAMES.PRODUCT.WEBVIEW.BOOKMARKS,
  profileSettings: ROUTE_NAMES.PRODUCT.WEBVIEW.PROFILE,
}

/**
 * Generate a path for a webview content drawer, if the `productSlug` is provided,
 * the path will be generated for the product webview
 * ie. /webview/bookmarks or /product/:productSlug/webview/bookmarks
 */
export function generateWebViewPath(path: WebViewContentDrawers, productSlug?: string) {
  if (!productSlug) return COMMUNITY_WEBVIEW_PATH_MAP[path]
  return generatePath(PRODUCT_WEBVIEW_PATH_MAP[path], { productSlug })
}

/**
 * Handles sending scroll position messages to the webview
 */
export function useWebViewScroll(opts: {
  parentRef: React.RefObject<HTMLDivElement>
  postWebViewMessage: (message: WebViewMessage) => void
}) {
  const { parentRef, postWebViewMessage } = opts

  useEffect(() => {
    if (!parentRef.current) return
    const el = parentRef.current

    const onScroll = () => {
      // Send the scroll position to the webview
      postWebViewMessage({
        type: WebViewMessageType.frontend_scroll,
        scroll: el.scrollTop,
      })

      // Every time the user scrolls, clear the scrollEndTimer and set a new one, this is
      // to simulate a scrollend event, since the scrollend event is not supported by Safari
      // @ts-expect-error
      clearTimeout(window.scrollEndTimer)
      // @ts-expect-error
      window.scrollEndTimer = setTimeout(() => {
        postWebViewMessage({
          type: WebViewMessageType.frontend_scrollend,
          scroll: el.scrollTop,
        })
      }, 100)
    }

    el.addEventListener("scroll", onScroll)

    return () => {
      el.removeEventListener("scroll", onScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export type WebViewHeader = {
  height: number
  insetTop: number
}

export function getWebViewHeader(): WebViewHeader | null {
  const headerMeta = document.querySelector('meta[name="header"]')
  if (!headerMeta) return null

  const height = headerMeta.getAttribute("height")
  const insetTop = headerMeta.getAttribute("inset-top")
  if (!height || !insetTop) return null

  return { height: parseInt(height), insetTop: parseInt(insetTop) }
}
