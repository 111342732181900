import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import Relay from "@/relay/relayUtils"
import { useSetLastOrganizationVisitedMutation } from "@utils/hook/__generated__/useSetLastOrganizationVisitedMutation.graphql"
import { useEffect } from "react"
import { graphql } from "relay-runtime"

/**
 * Set the last activity date time for the organization membership
 */
function useSetLastOrganizationVisited() {
  const activeOrganization = useActiveOrganization()

  const updateLastActivityDateTimeMutation =
    Relay.useAsyncMutation<useSetLastOrganizationVisitedMutation>(
      graphql`
        mutation useSetLastOrganizationVisitedMutation(
          $input: UpdateLastActivityDateTimeInput!
        ) {
          response: updateLastActivityDateTime(input: $input) {
            node {
              id
              lastActivityAt
            }
            errors {
              field
              message
            }
          }
        }
      `
    )

  useEffect(() => {
    // update org membership last_activity_at field
    commitMutation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization?.viewerMembership?.id])

  function commitMutation() {
    // If we are not authorized, or we are logging out, don't run the mutation
    if (!activeOrganization?.viewerMembership?.id) return
    if (location.pathname === ROUTE_NAMES.AUTHENTICATION.LOGOUT) return

    try {
      updateLastActivityDateTimeMutation({
        input: {
          organizationMembershipId: activeOrganization.viewerMembership?.id,
        },
      })
    } catch {
      // Don't break the app if we fail this request
    }
  }
}

export default useSetLastOrganizationVisited
