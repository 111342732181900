import { indexToLetter } from "@/web-form/utils/webFormFillerUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"

import { TestIDProps } from "@utils/typeUtils"

type WebFormResultCheckbox = "circle" | "square" | "transparent"

interface WebFormResultCheckboxProps extends TestIDProps {
  isSelected: boolean
  isCorrectOption: boolean
  optionIndex?: number
  variant: WebFormResultCheckbox
  showWrongIfNotSelected?: boolean
  isScored?: boolean
}

function WebFormResultCheckbox({
  testid = "WebFormResultCheckbox",
  isSelected,
  isCorrectOption,
  optionIndex,
  variant = "transparent",
  showWrongIfNotSelected = true,
  isScored = true,
}: WebFormResultCheckboxProps) {
  const classes = useStyles({
    variant,
    isCorrectOption,
    isSelected,
    showWrongIfNotSelected,
    isScored,
  })
  return (
    <div data-testid={testid} className={classes.container}>
      {renderIcon()}
    </div>
  )

  function renderIcon() {
    // This submission isn't being scored so render a radio button instead of checkbox if single-select
    if (!isScored && isSelected) {
      if (variant === "circle") return null
      return <DiscoIcon icon={"check"} height={24} width={24} color={"inherit"} />
    }
    // user selected correct option
    if (isCorrectOption && isSelected)
      return <DiscoIcon icon={"check"} height={24} width={24} color={"inherit"} />
    // user selected incorrect option or correct option is not selected
    if (isSelected || (isCorrectOption && showWrongIfNotSelected))
      return <DiscoIcon icon={"close"} height={24} width={24} color={"inherit"} />
    if (typeof optionIndex === "number")
      return (
        <DiscoText variant={"body-sm-uppercase"} color={"groovy.neutral.700"}>
          {indexToLetter(optionIndex)}
        </DiscoText>
      )
    return null
  }
}

type StyleProps = {
  variant: WebFormResultCheckbox
  isCorrectOption: boolean
  isSelected: boolean
  showWrongIfNotSelected: boolean
  isScored: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({
    variant,
    isCorrectOption,
    isSelected,
    showWrongIfNotSelected,
    isScored,
  }: StyleProps) => ({
    width: 24,
    height: 24,
    flex: "0 0 auto", // make sure the item doesn't shrink when rendered in a flex box
    borderRadius:
      variant === "circle"
        ? "50%"
        : variant === "square"
        ? theme.measure.borderRadius.default
        : "unset",
    border:
      variant === "circle" && !isScored && isSelected
        ? `7px solid ${theme.palette.primary.main}`
        : isSelected || variant === "transparent"
        ? "none"
        : `1px solid ${theme.palette.groovy.neutral[300]}`,
    backgroundColor:
      variant === "circle" && !isScored && isSelected
        ? theme.palette.common.white
        : variant === "square" && !isScored && isSelected
        ? theme.palette.primary.main
        : variant === "transparent" ||
          !isScored ||
          (!isSelected && (!isCorrectOption || !showWrongIfNotSelected))
        ? "transparent"
        : isCorrectOption && isSelected
        ? theme.palette.groovy.green[600]
        : theme.palette.groovy.red[600],
    color:
      variant === "transparent"
        ? isCorrectOption && isSelected
          ? theme.palette.groovy.green[600]
          : isSelected
          ? theme.palette.groovy.red[600]
          : theme.palette.groovy.neutral[700]
        : isSelected || isCorrectOption
        ? theme.palette.common.white
        : theme.palette.groovy.neutral[700],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0.25),
  }),
}))

export default WebFormResultCheckbox
