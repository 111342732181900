import { Theme, useMediaQuery } from "@material-ui/core"

function useIsMobile(): boolean {
  return useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  })
}
function useIsTablet(): boolean {
  return useMediaQuery((theme: Theme) => theme.breakpoints.only("sm"), {
    noSsr: true,
  })
}

export { useIsMobile, useIsTablet }
