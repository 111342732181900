import pluralize from "pluralize"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import {
  DAYS_IN_A_WEEK,
  DAY_IN_HRS,
  DAY_IN_S,
  HOUR_IN_S,
  MINUTE_IN_S,
  SECOND_IN_MS,
} from "../time/timeConstants"

type CountDownResults = ReturnType<typeof calculateRemainingTime>

export function calculateRemainingTime(target: Date, now = new Date()) {
  const delta = target.getTime() - now.getTime()
  const deltaInSeconds = delta / SECOND_IN_MS

  return {
    weeks: Math.floor(deltaInSeconds / (DAY_IN_S * DAYS_IN_A_WEEK)),
    days: Math.floor(deltaInSeconds / DAY_IN_S),
    hours: Math.floor((deltaInSeconds / HOUR_IN_S) % DAY_IN_HRS),
    minutes: Math.floor((deltaInSeconds / MINUTE_IN_S) % MINUTE_IN_S),
    seconds: Math.floor(deltaInSeconds % MINUTE_IN_S),
    delta: Math.floor(deltaInSeconds),
  }
}

export function countdownDataToMessage(countdown: CountDownResults) {
  if (countdown.weeks) {
    return `${countdown.weeks} ${pluralize("week", countdown.weeks)}`
  }
  if (countdown.days) {
    return `${countdown.days} ${pluralize("day", countdown.days)}`
  }
  if (countdown.hours) {
    return `${countdown.hours} ${pluralize("hour", countdown.hours)}${
      countdown.minutes === 0
        ? ""
        : ` and ${countdown.minutes} ${pluralize("minute", countdown.minutes)}`
    }`
  }
  if (countdown.minutes) {
    return `${countdown.minutes} ${pluralize("minute", countdown.minutes)}`
  }
  return `${countdown.seconds} ${pluralize("second", countdown.seconds)}`
}

/**
 * A React Hook that provides a countdown time
 * @param target the target date we're counting down to
 * @param cadence the rate of the timer in seconds
 */
function useCountDownTimer(
  target: Date,
  /**
   * Options to pass to the useCountDownTimer
   * @param showNegative whether or not the countdown timer should go into negative values
   */
  opts: {
    cadenceSecs?: number
    disabled?: boolean
    showNegative?: boolean
  } = {}
): CountDownResults {
  const { cadenceSecs = MINUTE_IN_S, disabled = false, showNegative = true } = opts

  const intervalIdRef = useRef<any>()
  const [countdown, setCountDown] = useState<CountDownResults>(() => {
    const initial = calculateRemainingTime(target)
    if (initial.delta >= 0 || showNegative) return initial
    return { weeks: 0, days: 0, hours: 0, minutes: 0, seconds: 0, delta: 0 }
  })

  useLayoutEffect(() => {
    if (disabled) return
    intervalIdRef.current = setInterval(() => {
      const data = calculateRemainingTime(target)

      if (data.delta >= 0) {
        setCountDown(data)
      }
    }, cadenceSecs * SECOND_IN_MS)

    return () => {
      clearInterval(intervalIdRef.current)
    }
  }, [cadenceSecs, target, disabled])

  useEffect(() => {
    // Stop updating the countdown once the time left has reached 0
    if (countdown.delta <= 0) {
      clearInterval(intervalIdRef.current)
    }
  }, [countdown.delta])

  return countdown
}

export default useCountDownTimer
