import { Accept } from "react-dropzone"

/**
 * Returns a promise that resolves the Base64 representation of the given File object.
 * @param {File} file File object to convert to Base64
 * @return {Promise} Promise
 */
function convertFileToBase64(file: null | undefined | File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    try {
      const reader = new FileReader()

      if (file) {
        reader.readAsDataURL(file)
        reader.onload = () =>
          typeof reader.result === "string" ? resolve(reader.result) : reject()
        reader.onerror = (error) => reject(error)
      } else {
        reject()
      }
    } catch (error) {
      reject(error)
    }
  })
}

/**
 * Strips and returns the Base64 data from a description
 * @param {string} description Base64 description of a file
 * @return {string} Data
 */
function getBase64Data(description: string): string | undefined {
  const splitted = description.split(";base64,")

  return splitted.length && splitted[1] ? splitted[1] : undefined
}

/**
 * Create a png thumbnail of a video File. See here: https://stackoverflow.com/a/63474748
 * @param file The base64 video file to snapshot
 * @param timestamp Time in seconds to grab the shot from
 * @returns Data URL for the png thumbnail
 */
function getVideoCoverFromBase64(video: string, timestamp = 0.0): Promise<File> {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video")
    videoPlayer.setAttribute("src", video)
    videoPlayer.load()
    videoPlayer.addEventListener("error", (ex) => {
      console.error(ex)
      reject(new Error(`error when loading video file`))
    })
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < timestamp) {
        reject(new Error("video is too short"))
        return
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = timestamp
        // eslint-disable-next-line no-magic-numbers
      }, 200)
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", async () => {
        const base64Cover = getVideoCoverFromElement(videoPlayer)
        const imageFile = await urlToFile(base64Cover, "video-cover.png", "image/png")
        resolve(imageFile)
      })
    })
  })
}

/**
 * Create a png base64 image from the current frame of a video element
 */
function getVideoCoverFromElement(videoEl: HTMLVideoElement): string {
  // define a canvas to have the same dimension as the video
  const canvas = document.createElement("canvas")
  canvas.width = videoEl.videoWidth
  canvas.height = videoEl.videoHeight
  // draw the video frame to canvas
  const ctx = canvas.getContext("2d")!
  ctx.drawImage(videoEl, 0, 0, canvas.width, canvas.height)
  const imageBase64 = ctx.canvas.toDataURL()
  canvas.remove()
  return imageBase64
}

/**
 * Convert a dataURL to a File object:
 * https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
 */
export async function urlToFile(
  url: string,
  filename: string,
  mimeType: string
): Promise<File> {
  const res = await fetch(url)
  const buffer = await res.arrayBuffer()
  return new File([buffer], filename, { type: mimeType })
}

function mbToBytes(bytes: number): number {
  return bytes * 1024 * 1024
}

async function getVideoDetails(file: File) {
  const loadVideo = (f: File) =>
    new Promise<HTMLVideoElement>((resolve, reject) => {
      try {
        const video = document.createElement("video")
        video.preload = "metadata"

        video.onloadedmetadata = function () {
          resolve(video)
        }

        video.onerror = function () {
          throw new Error(`Please select a valid video file`)
        }

        video.src = window.URL.createObjectURL(f)
      } catch (e) {
        reject(e)
      }
    })

  const video = await loadVideo(file)

  return video
}

/** Parse the accept list of mime types to a comma-separated list of extensions */
export function getFileExtensionsFromAccept(accept: Accept): string {
  return Object.values(accept)
    .map((fileType) => fileType.join(", "))
    .join(", ")
}

/** Get the file type label from filename
 * e.g. test.csv -> "csv"
 */
export function getFileTypeLabelFromFileName(filename: string) {
  return filename.split(".").pop()
}

/** Format bytes to a readable size string */
export function formatBytesToReadableString(bytes: number, decimals?: number) {
  if (bytes === 0) return "0 Bytes"

  const k = 1024
  const dm = (decimals || 2) < 0 ? 0 : decimals
  const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export {
  convertFileToBase64,
  getBase64Data,
  getVideoCoverFromBase64,
  getVideoCoverFromElement,
  getVideoDetails,
  mbToBytes,
}
