export const IMAGE_SIZES_IN_PIXELS = {
  SMALL: 100,
  MEDIUM: 300,
  LARGE: 600,
}

export const COVER_IMAGE_SIZE = {
  WIDTH: 1140,
  HEIGHT: 427,
}

/* eslint-disable no-magic-numbers */
export const ASPECT_RATIOS = {
  SQUARE: 1,
  COVER_PHOTO: 2,
  CURRICULUM_ITEM_THUMBNAIL: 2,
  CURRICULUM_ITEM_COVER_PHOTO: 3,
  BANNER: 4,
  LANDING_PAGE_BLOCK_COVER_PHOTO: 1280 / 520,
  LANDING_PAGE_BLOCK_PHOTO_WITH_TEXT: 619 / 520,
  CONTENT_ATTACHMENT_CAROUSEL_MEDIA: 635 / 357,
  ORGANIZATION_LOGO: 450 / 150,
}

export const COVER_PHOTO_PADDING_TOP = `${100 / ASPECT_RATIOS.COVER_PHOTO}%`
export const BANNER_PADDING_TOP = `${100 / ASPECT_RATIOS.BANNER}%`
/* eslint-enable no-magic-numbers */
