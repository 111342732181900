import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import useZoomConnectionOnWindowFocus from "@/zoom/util/hook/useZoomConnectionOnWindowFocus"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"

type ZoomConnectButtonProps = Omit<OverridableDiscoButtonProps, "onClick">

function ZoomConnectButton({ children, ...props }: ZoomConnectButtonProps) {
  const setConnectZoomButtonState = useZoomConnectionOnWindowFocus()[1]
  const activeOrganization = useActiveOrganization()!
  return (
    <OverridableDiscoButton onClick={connectZoom} {...props}>
      {children}
    </OverridableDiscoButton>
  )

  function connectZoom() {
    const win = window.open(
      `${ROUTE_NAMES.ZOOM_LANDING}?organizationSlug=${activeOrganization.slug}`,
      "_blank"
    )
    win?.focus()
    setConnectZoomButtonState(true)
  }
}

export default ZoomConnectButton
