import FormStore, { FormMutation } from "@/core/form/store/FormStore"
import { WebFormQuestionType } from "@/member-onboarding/__generated__/MemberOnboardingCustomProfileFieldQuestionFragment.graphql"
import { GlobalID, NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import {
  CreateWebFormSubmissionInput as ReadonlyCreateWebFormSubmissionInput,
  WebFormAnswerInput as ReadonlyWebFormAnswerInput,
} from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsProfileTabMutation.graphql"
import { webFormFillerUtils_getFormStateFromRevisionFragment$data } from "@/web-form/utils/__generated__/webFormFillerUtils_getFormStateFromRevisionFragment.graphql"
import { webFormFillerUtils_startWebFormSubmissionMutation } from "@/web-form/utils/__generated__/webFormFillerUtils_startWebFormSubmissionMutation.graphql"
import { WEB_FORM_RATING_SCALE } from "@/web-form/utils/webFormConstants"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { useCallback } from "react"
import { graphql } from "relay-runtime"

export type WebFormAnswerInput = Relay.DeepWriteable<ReadonlyWebFormAnswerInput>

export type CreateWebFormSubmissionInput = Omit<
  ReadonlyCreateWebFormSubmissionInput,
  "answers"
> & {
  answers: WebFormAnswerInput[]
}

export type WebFormFillerFormStore<T extends FormMutation<any> = any> = FormStore<
  {
    webFormSubmission?: CreateWebFormSubmissionInput | null
  },
  T
>

export type WebFormQuestionData = NodeFromConnection<
  webFormFillerUtils_getFormStateFromRevisionFragment$data["questions"]
>

export type WebFormFillerRevisionData =
  | Omit<webFormFillerUtils_getFormStateFromRevisionFragment$data, " $fragmentType">
  | null
  | undefined

/**
 * Transform graphql web form revision data into form input compatible with creating
 * web form submissions
 */
export function getWebFormSubmissionInput(
  revision: WebFormFillerRevisionData,
  existingAnswers?: WebFormAnswerInput[],
  opts: { neverSkipped?: boolean } = { neverSkipped: true }
): Omit<CreateWebFormSubmissionInput, "contentUsageId"> | null {
  if (!revision) return null
  const questions = Relay.connectionToArray(revision.questions)
  return {
    webFormRevisionId: revision.id,
    answers: questions.map((question) => {
      const existing = existingAnswers?.find((a) => a.webFormQuestionId === question.id)
      const existingSelected = existing?.selectedOptions?.filter((id) =>
        question.options?.some((o) => o.id === id)
      )
      return {
        webFormQuestionId: question.id,
        body: existing?.body || "",
        selectedOptions: existingSelected || [],
        isSkipped: opts.neverSkipped
          ? false
          : !(existing?.body || existingSelected?.length),
      }
    }),
  }
}

/**
 * Returns a letter from A-Z for a given index in a list
 * ie: 0 -> A, 1 -> B ... 25 -> Z, then repeats
 */
export function indexToLetter(index: number) {
  return String.fromCharCode((index % 26) + 65)
}

export const WEB_FORM_QUESTION_TYPE_PROMPTS: { [k in WebFormQuestionType]: string } = {
  link: "Provide a web address below",
  written_answer: "Type your answer below",
  multiple_select: "Select all that apply",
  single_select: "Select one",
  rating: "Select a rating",
  ranking: "Drag and drop to rank options",
  "%future added value": "Unknown Question Type",
}

export function webFormQuestionTypeHasOptions(type?: WebFormQuestionType) {
  return type === "multiple_select" || type === "single_select"
}

export function ratingOptionDisplayNumber(optionIndex: number, optionsLength: number) {
  if (optionsLength === WEB_FORM_RATING_SCALE.LARGE_WITH_ZERO) return optionIndex
  return optionIndex + 1
}

/**
 * Helper to create an in-progress submission for a web form if one doesn't already exist
 */
export function useStartWebFormSubmission({
  revisionId,
  usageId,
}: {
  revisionId: GlobalID
  usageId?: GlobalID | null
}) {
  const commit =
    Relay.useAsyncMutation<webFormFillerUtils_startWebFormSubmissionMutation>(graphql`
      mutation webFormFillerUtils_startWebFormSubmissionMutation(
        $input: CreateWebFormSubmissionInput!
      ) {
        response: createWebFormSubmission(input: $input) {
          node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `)

  return useCallback(async () => {
    const { response } = await commit({
      input: {
        webFormRevisionId: revisionId,
        contentUsageId: usageId,
      },
    })
    if (response.errors?.length) displayErrorToast(response.errors[0])

    return response.node?.id || null

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commit, revisionId, usageId])
}

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment webFormFillerUtils_getFormStateFromRevisionFragment on WebFormRevision {
    id
    webFormId
    questions {
      edges {
        node {
          id
          type
          richEditorBody
          ordering
          isRequired
          options {
            id
            label
          }
        }
      }
    }
  }
`
