import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import PollOptions from "@/web-form/filler/poll/PollOptions"
import { PollWebFormQuery } from "@/web-form/filler/poll/__generated__/PollWebFormQuery.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import EditorUtils from "@components/editor/EditorUtils"
import {
  DiscoButtonSkeleton,
  DiscoIcon,
  DiscoSection,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { range } from "@utils/array/arrayUtils"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

type Props = TestIDProps & {
  webFormId: GlobalID
  disabled?: boolean
}

function PollWebForm(props: Props) {
  const { testid = "PollWebForm", webFormId, disabled } = props
  const classes = useStyles()
  const isMobile = useIsMobile()
  const theme = useTheme()

  const { webForm } = useLazyLoadQuery<PollWebFormQuery>(
    graphql`
      query PollWebFormQuery($id: ID!) {
        webForm: node(id: $id) {
          ... on WebForm {
            createdByUserId
            ...PollWebForm_settingsFragment @relay(mask: false)
            currentRevision {
              questions(first: 1) {
                edges {
                  node {
                    id
                    richEditorBody
                    ...PollOptionsFragment
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: webFormId }
  )

  if (!webForm?.currentRevision) return null
  const question = Relay.connectionToArray(webForm.currentRevision.questions)[0]

  return (
    <DiscoSection
      border
      groovyDepths={"insideCard"}
      padding={isMobile ? 1.5 : undefined}
      className={classes.section}
    >
      <div className={classes.iconAndQuestion}>
        <div className={classes.icon}>
          <DiscoIcon icon={"question"} color={theme.palette.primary.contrastText} />
        </div>
        <div className={classes.question}>
          <DiscoText testid={`${testid}.question`} variant={"body-md-600"}>
            {EditorUtils.convertToPlainText(JSON.parse(question.richEditorBody))}
          </DiscoText>
        </div>
      </div>
      <PollOptions
        testid={testid}
        questionKey={question}
        createdByUserId={webForm.createdByUserId}
        settings={webForm.settings}
        disabled={disabled}
      />
    </DiscoSection>
  )
}

const useStyles = makeUseStyles((theme) => ({
  iconAndQuestion: {
    display: "flex",
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
  },
  icon: {
    height: "48px",
    width: "48px",
    borderRadius: theme.measure.borderRadius.medium,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    flexShrink: 0,
  },
  question: {
    display: "flex",
    alignItems: "center",
  },
  section: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[600]
        : theme.palette.background.paper,
  },
}))

function PollWebFormSkeleton() {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <DiscoSection groovyDepths={"insideCard"}>
      <div className={classes.iconAndQuestion}>
        <div className={classes.icon}>
          <DiscoIcon icon={"question"} color={theme.palette.primary.contrastText} />
        </div>
        <div style={{ flexGrow: 1 }}>
          <DiscoTextSkeleton width={"100%"} />
          <DiscoTextSkeleton width={"40%"} />
        </div>
      </div>
      <DiscoTextSkeleton width={"150px"} />
      {range(4).map((i) => (
        <div key={i} style={{ marginTop: theme.spacing(1) }}>
          <DiscoButtonSkeleton width={"100%"} />
        </div>
      ))}
    </DiscoSection>
  )
}

export default Relay.withSkeleton({
  component: PollWebForm,
  skeleton: PollWebFormSkeleton,
})

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment PollWebForm_settingsFragment on WebForm {
    settings {
      membersSeeAllResults
      endDate
      allowSubmissionDeletion
    }
  }
`
