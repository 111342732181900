/**
 * @generated SignedSource<<a03dd431f06f25cdeb7dd314d78f3311>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WebFormQuestionType = "single_select" | "multiple_select" | "written_answer" | "link" | "rating" | "ranking" | "%future added value";
export type GenerateAiQuizInput = {
  moduleContentUsageId: string;
  organizationId: string;
  productId?: string | null;
};
export type useGenerateAIQuizMutation$variables = {
  input: GenerateAiQuizInput;
};
export type useGenerateAIQuizMutation$data = {
  readonly response: {
    readonly data: ReadonlyArray<{
      readonly type: WebFormQuestionType;
      readonly richEditorBody: string;
      readonly options: ReadonlyArray<{
        readonly id: string;
        readonly label: string;
      }> | null;
      readonly answerKey: {
        readonly correctIds: ReadonlyArray<string>;
      } | null;
      readonly isRequired: boolean | null;
    }> | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type useGenerateAIQuizMutation = {
  variables: useGenerateAIQuizMutation$variables;
  response: useGenerateAIQuizMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenerateAiQuizResponse",
    "kind": "LinkedField",
    "name": "generateAiQuiz",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GenerateWebFormQuestionResponse",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "richEditorBody",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AiWebFormQuestionOption",
            "kind": "LinkedField",
            "name": "options",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AiWebFormQuestionAnswerKey",
            "kind": "LinkedField",
            "name": "answerKey",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "correctIds",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isRequired",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useGenerateAIQuizMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useGenerateAIQuizMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "957ac18eced26701ba4d61d3dee9c4ba",
    "id": null,
    "metadata": {},
    "name": "useGenerateAIQuizMutation",
    "operationKind": "mutation",
    "text": "mutation useGenerateAIQuizMutation(\n  $input: GenerateAiQuizInput!\n) {\n  response: generateAiQuiz(input: $input) {\n    data {\n      type\n      richEditorBody\n      options {\n        id\n        label\n      }\n      answerKey {\n        correctIds\n      }\n      isRequired\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1926a099ccd749aa9a91ffc046805b3";

export default node;
