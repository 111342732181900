import { GlobalID } from "@/relay/RelayTypes"
import { DeleteWebFormSubmissionButtonMutation } from "@/web-form/submission/__generated__/DeleteWebFormSubmissionButtonMutation.graphql"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoConfirmationModal from "@disco-ui/modal/DiscoConfirmationModal"
import { usePermissionsFragment$key } from "@utils/hook/__generated__/usePermissionsFragment.graphql"
import usePermissions from "@utils/hook/usePermissions"
import { useState } from "react"
import { useMutation } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends OverridableDiscoButtonProps {
  submissionId: GlobalID
  onRemove?: () => void
  contentUsageKey?: usePermissionsFragment$key | null
  confirmDelete?: boolean
}

function DeleteWebFormSubmissionButton(props: Props) {
  const {
    submissionId,
    onRemove,
    children,
    contentUsageKey,
    testid = "DeleteWebFormSubmissionButton",
    confirmDelete = true,
    ...rest
  } = props
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [commit, isDeleting] = useMutation<DeleteWebFormSubmissionButtonMutation>(graphql`
    mutation DeleteWebFormSubmissionButtonMutation($id: ID!) {
      response: deleteWebFormSubmission(id: $id) {
        deletedWebFormSubmissionId @deleteRecord
        errors {
          field
          message
        }
      }
    }
  `)

  const permissions = usePermissions(contentUsageKey)
  if (contentUsageKey && !permissions.has("content.manage")) return null

  return (
    <>
      <OverridableDiscoButton
        {...rest}
        onClick={handleClick}
        disabled={isDeleting || rest.disabled}
      >
        {children}
      </OverridableDiscoButton>

      <DiscoConfirmationModal
        testid={`${testid}Modal`}
        isOpen={isModalOpen}
        onClose={closeModal}
        title={`Are you sure you want to delete this submission?`}
        disableTypeToConfirm
        confirmButtonProps={{
          onClick: deleteWebFormSubmission,
          disabled: isDeleting,
          shouldDisplaySpinner: isDeleting,
          children: "Yes, delete this submission",
        }}
        modalContentLabel={"Delete Submission Confirmation Modal"}
        description={`This will permanently delete this submission. Are you sure you want to delete this submission?`}
      />
    </>
  )

  function handleClick() {
    if (confirmDelete) {
      openModal()
      return
    }
    deleteWebFormSubmission()
  }

  function deleteWebFormSubmission() {
    commit({
      variables: {
        id: submissionId,
      },
      onCompleted() {
        if (onRemove) onRemove()
        if (!confirmDelete) return
        closeModal()
        displaySuccessToast({
          message: `Submission deleted`,
          testid: `${testid}.success-toast`,
        })
      },
      onError(error) {
        displayErrorToast(error)
      },
    })
  }

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }
}

export default DeleteWebFormSubmissionButton
