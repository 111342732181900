import { WebFormQuestionType } from "@/content-usage/__generated__/ContentModulesDragDropProvider_UpdateContentUsageMutation.graphql"
import { WebFormAnswerVariant } from "@/web-form/filler/WebFormAnswer"
import WebFormResultCheckbox from "@/web-form/result/WebFormResultCheckbox"
import WebFormResultChip from "@/web-form/result/WebFormResultChip"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { hexOpacity } from "@utils/color/colorUtils"
import { TestIDProps } from "@utils/typeUtils"

interface WebFormAnswerOptionResultProps extends TestIDProps {
  variant?: WebFormAnswerVariant
  type: WebFormQuestionType
  option: {
    isSelected: boolean
    isCorrectOption: boolean
    id: string
    label: string
  }
  optionIndex: number
  showWrongIfNotSelected?: boolean
  showCorrectAnswer?: boolean
  isScored: boolean
}

function WebFormAnswerOptionResult({
  testid,
  variant,
  type,
  option: { isSelected, isCorrectOption, ...option },
  optionIndex,
  showWrongIfNotSelected,
  showCorrectAnswer = true,
  isScored,
}: WebFormAnswerOptionResultProps) {
  const classes = useStyles({ variant, isCorrectOption, isSelected, isScored })

  return (
    <div data-testid={testid} className={classes.container}>
      <div className={classes.item}>
        <WebFormResultCheckbox
          isSelected={isSelected}
          isCorrectOption={isCorrectOption}
          optionIndex={optionIndex}
          variant={
            type === "single_select"
              ? "circle"
              : type === "multiple_select"
              ? "square"
              : "transparent"
          }
          showWrongIfNotSelected={showWrongIfNotSelected && showCorrectAnswer}
          isScored={isScored}
        />
        <DiscoText>{option.label}</DiscoText>
      </div>

      {isScored && (
        <div className={classes.item}>
          {isCorrectOption && !isSelected && (
            <DiscoText
              testid={`${testid}.did-not-select`}
              variant={"body-xs-500"}
              color={"groovy.neutral.700"}
            >
              {"Did not select"}
            </DiscoText>
          )}
          <WebFormResultChip
            testid={`${testid}.result`}
            isCorrectOption={isCorrectOption}
            isSelected={isSelected}
          />
        </div>
      )}
    </div>
  )
}

type StyleProps = {
  variant?: WebFormAnswerVariant
  isCorrectOption?: boolean
  isSelected: boolean
  isScored: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ variant, isSelected, isCorrectOption, isScored }: StyleProps) => ({
    padding: theme.spacing(variant === "compact" ? 0.75 : 1.5, 1.5),
    margin: `${theme.spacing(0, 0, 1.5)} !important`,
    border: `1px solid ${
      !isScored && isSelected
        ? theme.palette.primary.main
        : theme.palette.groovy[
            isCorrectOption && isSelected ? "green" : isSelected ? "red" : "neutral"
          ][theme.palette.type === "dark" ? 500 : 300]
    }`,
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor:
      !isScored && isSelected
        ? hexOpacity(theme.palette.primary.main, 0.1)
        : isCorrectOption && isSelected
        ? theme.palette.groovy.green[theme.palette.type === "dark" ? 700 : 100]
        : isSelected
        ? theme.palette.groovy.red[theme.palette.type === "dark" ? 700 : 100]
        : "transparent",
    display: "flex",
    gap: theme.spacing(1.5),
    alignItems: "center",
    justifyContent: "space-between",
  }),
  item: {
    display: "flex",
    gap: theme.spacing(1.5),
    flex: "0 1 auto",
    alignItems: "center",
  },
}))

export default WebFormAnswerOptionResult
