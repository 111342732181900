import { useCallback, useEffect, useRef } from "react"

/** Debounce execution of a callback function */
export default function useDebounce<T extends Array<any>>(
  callback: (...args: T) => void,
  timeout = 1000,
  opts: { resetOnUpdate?: boolean } = {}
) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const argsRef = useRef<any>()

  const debounced = useCallback(
    (...args: T) => {
      if (timeoutRef.current) clearInterval(timeoutRef.current)
      argsRef.current = args
      timeoutRef.current = setTimeout(() => {
        callback(...args)
        timeoutRef.current = null
      }, timeout)
    },
    [callback, timeout]
  )

  // If the callback changes, reset the timer to use the new callback instead of the
  // old one with potentially stale variable references
  useEffect(() => {
    if (!opts.resetOnUpdate || !timeoutRef.current) return
    debounced(...argsRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced])

  // Clear interval on unmount to prevent memory leaks
  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearInterval(timeoutRef.current)
    }
  }, [])

  return debounced
}
