import { FinalErrorShape } from "@/core/network-manager/networkUtils"
import ExceptionTransformer from "@hipo/hipo-exceptions-js"
import { ApiErrorShape } from "../../core/network-manager/networkModels"
import { sendSentryAnException } from "../../core/sentryHandler"
import { isProduction } from "../globalVariables"
import { ArrayToUnion } from "../typeUtils"
import { ERROR_TYPES, GENERIC_ERROR_MESSAGE } from "./errorConstants"

const exceptionTransformer = new ExceptionTransformer(GENERIC_ERROR_MESSAGE, {
  onUnexpectedException: sendSentryAnException,
})

function generateErrorMessage(
  errorInfo: ApiErrorShape | null | undefined | Error,
  options?: {
    skipTypes?: Array<ArrayToUnion<typeof ERROR_TYPES>>
    knownErrorKeys?: string[] | null
    displayGenericMessage?: boolean
  }
) {
  let message = ""

  if (errorInfo) {
    if (errorInfo instanceof Error) {
      message = errorInfo.message || GENERIC_ERROR_MESSAGE
    } else {
      message = exceptionTransformer.generateErrorMessage(errorInfo, options)
    }
  } else if (options?.displayGenericMessage) {
    message = GENERIC_ERROR_MESSAGE
  }
  return message
}

function generateSpecificFieldError(errorInfo: ApiErrorShape | null | undefined) {
  return exceptionTransformer.generateSpecificFieldError(errorInfo)
}

function generateErrorMessageFromCaughtError(error: any) {
  let errorObj = error?.data

  if (error instanceof Error || typeof error?.fallback_message !== "undefined") {
    errorObj = error
  }

  return generateErrorMessage(errorObj)
}

function throwError(errorString: string) {
  if (!isProduction()) {
    throw new Error(errorString)
  }
}

function createErrorWithApiErrorShapeFromString(
  text: string,
  type: ArrayToUnion<typeof ERROR_TYPES> = "FrontEnd"
): ApiErrorShape {
  return {
    type,
    detail: {
      non_field_errors: [text],
    },
    fallback_message: text,
  }
}

function hasApiErrorShape(x: unknown): x is ApiErrorShape {
  return Boolean(typeof x === "object" && x?.hasOwnProperty("fallback_message"))
}

function isApiError(x: unknown): x is FinalErrorShape {
  return isErrorWithData(x) && hasApiErrorShape(x.data)
}

function isErrorWithData(x: unknown): x is { data: any } {
  return Boolean(typeof x === "object" && x?.hasOwnProperty("data"))
}

export {
  generateErrorMessage,
  generateSpecificFieldError,
  generateErrorMessageFromCaughtError,
  throwError,
  createErrorWithApiErrorShapeFromString,
  isApiError,
}
