import { useEffect } from "react"

function useResizeObserver<T extends Element>(
  ref: React.RefObject<T | undefined>,
  cb: (el: ResizeObserverEntry["target"]) => void
) {
  useEffect(() => {
    const el = ref.current
    if (!el) return
    const observer = new ResizeObserver((observerEntries) => {
      const entry = observerEntries[0]
      cb(entry.target)
    })
    observer.observe(el)
    return () => {
      // register last el state before cleanup
      cb(el)
      observer.unobserve(el)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, cb])
}

export default useResizeObserver
