/**
 * @generated SignedSource<<1254186a07802d003360caa97c5f9640>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WebFormQuestionType = "single_select" | "multiple_select" | "written_answer" | "link" | "rating" | "ranking" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type webFormEditorUtils_questionFragment$data = {
  readonly id: string;
  readonly type: WebFormQuestionType;
  readonly ordering: number;
  readonly richEditorBody: string;
  readonly isRequired: boolean;
  readonly options: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
  }> | null;
  readonly answerKey: {
    readonly correctIds: ReadonlyArray<string>;
    readonly feedback: string | null;
  } | null;
  readonly " $fragmentType": "webFormEditorUtils_questionFragment";
};
export type webFormEditorUtils_questionFragment$key = {
  readonly " $data"?: webFormEditorUtils_questionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"webFormEditorUtils_questionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "webFormEditorUtils_questionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordering",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorBody",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormQuestionOption",
      "kind": "LinkedField",
      "name": "options",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormQuestionAnswerKey",
      "kind": "LinkedField",
      "name": "answerKey",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "correctIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "feedback",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WebFormQuestion",
  "abstractKey": null
};
})();

(node as any).hash = "5f2f7880fd02d0b83f2f571a4b18e970";

export default node;
