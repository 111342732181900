import WebFormAnswerOptionResult from "@/web-form/result/WebFormAnswerOptionResult"
import WebFormAnswerRankingResult from "@/web-form/result/WebFormAnswerRankingResult"
import WebFormAnswerRatingResult from "@/web-form/result/WebFormAnswerRatingResult"
import { QuizAnswerData, QuizQuestionData } from "@/web-form/utils/webFormQueryUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface WebFormAnswerResultProps extends TestIDProps {
  answer: QuizAnswerData & { question: QuizQuestionData }
  showCorrectAnswer?: boolean
}

function WebFormAnswerResult({
  testid = "WebFormAnswerResult",
  answer,
  showCorrectAnswer = true,
}: WebFormAnswerResultProps) {
  const { selectedOptions, question, result } = answer
  const classes = useStyles()

  if (question.type === "written_answer")
    return (
      <DiscoText
        testid={`${testid}.text`}
        variant={"body-sm"}
        className={classes.answerText}
      >
        {answer.body}
      </DiscoText>
    )

  if (question.type === "rating")
    return (
      <WebFormAnswerRatingResult
        testid={testid}
        options={question.options!}
        selected={selectedOptions}
      />
    )

  if (question.type === "ranking")
    return (
      <WebFormAnswerRankingResult
        testid={testid}
        options={question.options!}
        selected={selectedOptions}
      />
    )

  const options = question.options?.map((o) => ({
    ...o,
    isSelected: selectedOptions?.includes(o.id) ?? false,
    isCorrectOption: result?.correctIds?.includes(o.id) ?? false,
  }))
  const hasSelectedOptions = !!options?.find((o) => o.isSelected)
  const showWrongIfNotSelected = !hasSelectedOptions && answer.isCorrect === false

  return (
    <>
      {options?.map((o, i) => (
        <WebFormAnswerOptionResult
          key={o.id}
          testid={`${testid}.option.${o.label}`}
          type={question.type}
          option={o}
          optionIndex={i}
          showWrongIfNotSelected={showWrongIfNotSelected}
          showCorrectAnswer={showCorrectAnswer}
          isScored={Boolean(result)}
        />
      ))}
    </>
  )
}

const useStyles = makeUseStyles({
  answerText: {
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },
})

export default observer(WebFormAnswerResult)
