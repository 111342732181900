import FormStore from "@/core/form/store/FormStore"
import { UpdateWebFormInput } from "@/web-form/editor/poll/__generated__/UpdatePollSettingsMutation.graphql"
import { CreateWebFormInput } from "@/web-form/utils/webFormEditorUtils"
import { DiscoCheckbox, DiscoFormControl, DiscoInput } from "@disco-ui"
import DiscoDatetimePicker from "@disco-ui/date/DiscoDatetimePicker"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { addHours } from "date-fns"
import { observer } from "mobx-react-lite"

type Props = TestIDProps & {
  form: FormStore<CreateWebFormInput> | FormStore<UpdateWebFormInput>
}

function WebFormSettings(props: Props) {
  const { testid = "WebFormSettings", form } = props

  return (
    <>
      <DiscoFormControl marginBottom={0}>
        <DiscoCheckbox
          testid={`${testid}.enable-end-date`}
          label={"Set end date"}
          checked={Boolean(form.state.settings?.endDate)}
          onChange={handleEndDateToggle}
        />
      </DiscoFormControl>
      {form.state.settings?.endDate && (
        <DiscoFormControl marginBottom={0}>
          <DiscoDatetimePicker
            testid={`${testid}.end-date`}
            onSave={handleEndDateSave}
            initialDate={new Date(form.state.settings.endDate)}
          >
            {({ onClick, selectedDatetime, selectedTimezone }) => (
              <DiscoInput
                data-testid={`${testid}.end-date`}
                readOnly
                value={
                  selectedDatetime
                    ? formatDateWithOptions({
                        format:
                          DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT,
                        timeZone: selectedTimezone,
                      })(selectedDatetime)
                    : "Select a time"
                }
                onClick={onClick}
              />
            )}
          </DiscoDatetimePicker>
        </DiscoFormControl>
      )}
      <DiscoFormControl marginBottom={0}>
        <DiscoCheckbox
          testid={`${testid}.allow-submission-deletion`}
          label={`Allow responses to be changed${
            form.state.settings?.endDate ? " before the end date" : ""
          }`}
          checked={Boolean(form.state.settings?.allowSubmissionDeletion)}
          onChange={handleToggleAllowSubmissionDeletion}
        />
      </DiscoFormControl>
    </>
  )

  function handleEndDateToggle(checked: boolean) {
    if (!form.state.settings) form.state.settings = {}
    form.state.settings.endDate = checked ? addHours(new Date(), 24).toISOString() : null
  }

  function handleEndDateSave(date: Date) {
    if (!form.state.settings) form.state.settings = {}
    form.state.settings.endDate = date.toISOString()
  }

  function handleToggleAllowSubmissionDeletion(checked: boolean) {
    if (!form.state.settings) form.state.settings = {}
    form.state.settings.allowSubmissionDeletion = checked
  }
}

export default observer(WebFormSettings)
