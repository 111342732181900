import { WebFormQuestionType } from "@/web-form/utils/__generated__/webFormEditorUtils_getFormStateFromRevisionFragment.graphql"
import { useWebFormQuestionTypes } from "@/web-form/utils/useWebFormQuestionTypes"
import { useWebFormEditorContext } from "@/web-form/utils/webFormEditorUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

type Props = TestIDProps & {
  questionIndex: number
  questionType: WebFormQuestionType
  classes?: ClassNameMap<"questionType">
}

export default function WebFormEditorQuestionType(props: Props) {
  const { questionIndex, questionType, testid = "WebFormEditorQuestion" } = props
  const classes = useStyles()
  const types = useWebFormQuestionTypes()
  const typeData = types.find((type) => type.value === questionType)!
  const { getQuestionConfig } = useWebFormEditorContext() || {}
  const config = getQuestionConfig?.(questionIndex)

  return (
    <div
      className={classNames(
        classes.root,
        config?.classes?.questionType,
        props?.classes?.questionType
      )}
    >
      <DiscoText variant={"body-md-600"}>{`${questionIndex + 1}.`}</DiscoText>
      {typeData.icon}
      <DiscoText variant={"body-sm-500"} testid={`${testid}.type`}>
        {typeData.title}
      </DiscoText>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[500]
        : theme.palette.groovy.neutral[100],
    padding: "10px 8px",
    borderRadius: theme.measure.borderRadius.big,
  },
}))
