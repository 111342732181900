import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import { WebFormQuestionType } from "@/web-form/utils/__generated__/webFormEditorUtils_getFormStateFromRevisionFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoTooltip } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { ReactNode, useMemo } from "react"

type QuestionTypeOption = {
  title: string
  subtitle: string
  value: WebFormQuestionType
  icon: ReactNode
}

export const WEB_FORM_QUESTION_TYPE_TITLES: Record<WebFormQuestionType, string> = {
  single_select: "Single Select",
  multiple_select: "Multiple Select",
  written_answer: "Written Answer",
  link: "Link",
  rating: "Rating",
  ranking: "Ranking",
  "%future added value": "Unknown",
}

export function useWebFormQuestionTypes(): QuestionTypeOption[] {
  const theme = useTheme()
  const labels = useLabels()
  const activeProduct = useActiveProduct()
  const label = activeProduct ? labels.product_member : labels.organization_member
  const classes = useStyles()

  return useMemo(
    () => [
      {
        title: WEB_FORM_QUESTION_TYPE_TITLES.single_select,
        subtitle: `${label.plural} select one option.`,
        value: "single_select",
        icon: (
          <DiscoTooltip content={"Single Select"}>
            <span>
              <DiscoIcon
                icon={"check"}
                background={theme.palette.groovy.blue[400]}
                color={`${theme.palette.common.white} !important`}
                className={classes.optionIcon}
              />
            </span>
          </DiscoTooltip>
        ),
      },
      {
        title: WEB_FORM_QUESTION_TYPE_TITLES.multiple_select,
        subtitle: `${label.plural} select all applicable options.`,
        value: "multiple_select",
        icon: (
          <DiscoTooltip content={"Multiple Select"}>
            <span>
              <DiscoIcon
                icon={"done-all"}
                background={theme.palette.groovy.orange[500]}
                color={`${theme.palette.common.white} !important`}
                padding={0.25}
                className={classes.optionIcon}
              />
            </span>
          </DiscoTooltip>
        ),
      },
      {
        title: WEB_FORM_QUESTION_TYPE_TITLES.rating,
        subtitle: `${label.plural} rate a topic on a scale.`,
        value: "rating",
        icon: (
          <DiscoTooltip content={"Rating"}>
            <span>
              <DiscoIcon
                icon={"star"}
                background={theme.palette.groovy.purple[500]}
                color={`${theme.palette.common.white} !important`}
                padding={0.125}
                className={classes.optionIcon}
              />
            </span>
          </DiscoTooltip>
        ),
      },
      {
        title: WEB_FORM_QUESTION_TYPE_TITLES.ranking,
        subtitle: `${label.plural} drag and drop to rank options.`,
        value: "ranking",
        icon: (
          <DiscoTooltip content={"Ranking"}>
            <span>
              <DiscoIcon
                icon={"bar-chart"}
                background={theme.palette.groovy.yellow[500]}
                color={`${theme.palette.common.black} !important`}
                padding={0.25}
                className={classes.optionIcon}
              />
            </span>
          </DiscoTooltip>
        ),
      },
      {
        title: WEB_FORM_QUESTION_TYPE_TITLES.written_answer,
        subtitle: `${label.plural} type out an answer.`,
        value: "written_answer",
        icon: (
          <DiscoTooltip content={"Written Answer"}>
            <span>
              <DiscoIcon
                icon={"align-center"}
                background={theme.palette.groovy.green[400]}
                color={`${theme.palette.common.black} !important`}
                padding={0.125}
                className={classes.optionIcon}
              />
            </span>
          </DiscoTooltip>
        ),
      },
      {
        title: WEB_FORM_QUESTION_TYPE_TITLES.link,
        subtitle: `${label.plural} type in a URL.`,
        value: "link",
        icon: (
          <DiscoTooltip content={"Link"}>
            <span>
              <DiscoIcon
                icon={"link"}
                background={theme.palette.groovy.yellow[400]}
                color={`${theme.palette.common.black} !important`}
                className={classes.optionIcon}
              />
            </span>
          </DiscoTooltip>
        ),
      },
    ],
    [classes.optionIcon, label.plural, theme]
  )
}

const useStyles = makeUseStyles((theme) => ({
  optionIcon: {
    borderRadius: theme.measure.borderRadius.default,
    width: "20px",
    height: "20px",
    flexShrink: 0,
  },
}))
