import WebFormInput from "@/web-form/filler/WebFormInput"
import { WebFormQuestionType } from "@/web-form/utils/__generated__/webFormEditorUtils_getFormStateFromRevisionFragment.graphql"
import { WebFormFillerFormStore } from "@/web-form/utils/webFormFillerUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

export type WebFormAnswerVariant = "default" | "compact" | "poll"

export type WebFormAnswerProps = TestIDProps & {
  question: {
    type: WebFormQuestionType
    options: readonly { id: string; label: string }[] | null
  }
  form: WebFormFillerFormStore
  answerIndex: number
  variant?: WebFormAnswerVariant
}

function WebFormAnswer(props: WebFormAnswerProps) {
  const {
    question,
    form,
    answerIndex,
    testid = "WebFormAnswer",
    variant = "default",
  } = props
  const classes = useStyles()
  const answer = form.state.webFormSubmission?.answers[answerIndex]
  if (!answer) return null

  const errors =
    form.errorsByField[`answer.${answerIndex}.body`] ||
    form.errorsByField[`answer.${answerIndex}.selectedOptions`]

  return (
    <DiscoFormControl
      marginBottom={0}
      className={classes.root}
      error={Boolean(errors)}
      errorMessages={errors}
    >
      <WebFormInput
        testid={testid}
        variant={variant}
        answer={answer}
        question={question}
      />
    </DiscoFormControl>
  )
}

const useStyles = makeUseStyles({
  root: {
    padding: 0,
  },
})

export default observer(WebFormAnswer)
