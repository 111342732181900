import { Location } from "history"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const usePageLoad = (cb: (location: Location) => void) => {
  const location = useLocation()

  useEffect(() => {
    if (cb && typeof cb === "function") {
      cb(location)
    }
  }, [location, cb])
}

export default usePageLoad
