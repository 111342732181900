/**
 * @generated SignedSource<<b07d272e2e055460dcbe5379996d6a7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateLastActivityDateTimeInput = {
  organizationMembershipId: string;
};
export type useSetLastOrganizationVisitedMutation$variables = {
  input: UpdateLastActivityDateTimeInput;
};
export type useSetLastOrganizationVisitedMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly lastActivityAt: string | null;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type useSetLastOrganizationVisitedMutation = {
  variables: useSetLastOrganizationVisitedMutation$variables;
  response: useSetLastOrganizationVisitedMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLastActivityDateTimeResponse",
    "kind": "LinkedField",
    "name": "updateLastActivityDateTime",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationMembership",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastActivityAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSetLastOrganizationVisitedMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSetLastOrganizationVisitedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ebb1aa75b65ccdea7c644d19d0ae8998",
    "id": null,
    "metadata": {},
    "name": "useSetLastOrganizationVisitedMutation",
    "operationKind": "mutation",
    "text": "mutation useSetLastOrganizationVisitedMutation(\n  $input: UpdateLastActivityDateTimeInput!\n) {\n  response: updateLastActivityDateTime(input: $input) {\n    node {\n      id\n      lastActivityAt\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cfdc6dea0fc89ce68877596c78997e05";

export default node;
