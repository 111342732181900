import { DependencyList, EffectCallback, useEffect, useRef } from "react"

/**
 * useEffect hook that skips running the effect callback during the component's initial
 * render/mount.
 */
export default function useEffectOnUpdate(effect: EffectCallback, deps?: DependencyList) {
  const isMount = useRef(true)
  useEffect(() => {
    if (isMount.current) {
      isMount.current = false
      return
    }
    return effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
