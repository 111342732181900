import { WebFormAnswerVariant } from "@/web-form/filler/WebFormAnswer"
import { WebFormQuestionAnswers } from "@/web-form/filler/WebFormInput"
import AnswerOptionStats, {
  useWebFormAnswerOptionStats,
} from "@/web-form/filler/answer-inputs/AnswerOptionStats"
import { indexToLetter } from "@/web-form/utils/webFormFillerUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoCheckbox, DiscoIcon, DiscoText } from "@disco-ui"
import { hexOpacity } from "@utils/color/colorUtils"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"

type Props = TestIDProps & {
  options: readonly { id: string; label: string }[]
  answer: { selectedOptions?: string[] | null }
  variant?: WebFormAnswerVariant
  disabled?: boolean
  allAnswers?: WebFormQuestionAnswers
}

function MultipleSelectAnswerInput(props: Props) {
  const {
    options,
    answer,
    testid = "MultipleSelectAnswerInput",
    variant = "default",
    disabled,
    allAnswers,
  } = props
  const optionStats = useWebFormAnswerOptionStats(options, allAnswers)
  const classes = useStyles({ variant, showAnswers: Boolean(allAnswers), disabled })

  return (
    <>
      {options.map((option, i) => {
        const isSelected = answer.selectedOptions?.some((id) => id === option.id) || false
        return (
          <DiscoCheckbox
            key={option.id}
            checked={isSelected}
            onChange={(checked) => handleChange(checked, option.id)}
            label={
              <>
                {optionStats[option.id] && (
                  <div
                    className={classes.percentBar}
                    style={{ width: `${optionStats[option.id].percent}%` }}
                  />
                )}
                <div className={classes.innerLabel}>
                  <DiscoText
                    variant={variant === "compact" ? "body-sm" : "body-md"}
                    testid={`${testid}.option.${i}.label`}
                  >
                    {option.label}
                  </DiscoText>
                  {optionStats[option.id] && (
                    <AnswerOptionStats
                      testid={`${testid}.option.${i}.stats`}
                      stats={optionStats[option.id]}
                      isSelected={isSelected}
                    />
                  )}
                </div>
              </>
            }
            classes={{
              root: classNames(classes.checkboxRoot, {
                [classes.checkboxRootChecked]: isSelected,
              }),
              label: classes.label,
            }}
            customIcon={<span className={classes.uncheckedIcon}>{indexToLetter(i)}</span>}
            customCheckedIcon={
              disabled ? (
                <span className={classNames(classes.uncheckedIcon, classes.checkedIcon)}>
                  <DiscoIcon icon={"check"} height={22} width={22} />
                </span>
              ) : undefined
            }
            testid={`${testid}.option.${i}`}
            disabled={disabled}
          />
        )
      })}
    </>
  )

  function handleChange(checked: boolean, optionId: string) {
    if (!answer.selectedOptions) answer.selectedOptions = []
    if (!checked) {
      answer.selectedOptions.splice(answer.selectedOptions.indexOf(optionId), 1)
      return
    }

    // Add the selected option ID in the same order as the options
    answer.selectedOptions = options
      .map((o) => o.id)
      .filter((id) => id === optionId || answer.selectedOptions!.includes(id))
  }
}

type StyleProps = {
  variant: WebFormAnswerVariant
  showAnswers?: boolean
  disabled?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  checkboxRoot: ({ variant, disabled }: StyleProps) => ({
    padding: theme.spacing(
      variant === "compact" ? 0 : variant === "poll" ? 0.25 : 0.75,
      0.75
    ),
    margin: `${theme.spacing(0, 0, variant === "poll" ? 1 : 1.5)} !important`,
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: variant === "poll" ? undefined : theme.palette.groovyDepths.insideCard,
    display: "flex",
    position: "relative",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      borderColor: disabled
        ? theme.palette.groovy.neutral[300]
        : theme.palette.primary.main,
    },
    "&:last-child": {
      marginBottom: "0 !important",
    },
  }),
  checkboxRootChecked: ({ variant, showAnswers, disabled }: StyleProps) => ({
    "&, &:hover": {
      borderColor: theme.palette.primary.main,
    },
    ...styleIf(variant === "poll", {
      backgroundColor:
        showAnswers || disabled
          ? `${hexOpacity(theme.palette.primary.main, 0.1)} !important`
          : undefined,
      "& $percentBar": {
        backgroundColor: hexOpacity(theme.palette.primary.main, 0.1),
      },
    }),
  }),
  uncheckedIcon: {
    backgroundColor: theme.palette.groovy.neutral[100],
    border: `1px solid ${
      theme.palette.type === "dark"
        ? theme.palette.groovy.grey[500]
        : theme.palette.groovy.neutral[200]
    }`,
    borderRadius: "6px",
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    color: theme.palette.common.black,
    // Needed to raise the icon above percentBar
    zIndex: 1,
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    "& svg": {
      color: theme.palette.primary.contrastText,
    },
  },
  label: {
    flexGrow: 1,
  },
  innerLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: theme.spacing(0.75),
    position: "relative",
  },
  percentBar: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.neutral[300]
        : theme.palette.groovy.neutral[100],
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    transition: "width 0.5s ease-in-out",
  },
}))

export default observer(MultipleSelectAnswerInput)
