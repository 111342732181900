import {
  CreateWebFormRevisionInput as ReadonlyCreateWebFormRevisionInput,
  WebFormQuestionInput as ReadonlyWebFormQuestionInput,
} from "@/admin/members/profile-fields/__generated__/AdminProfileFieldsPageMutation.graphql"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID, ValidationError } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { CreateWebFormInput as ReadonlyCreateWebFormInput } from "@/web-form/editor/poll/__generated__/PollWebFormEditorMutation.graphql"
import { webFormEditorUtils_getFormStateFromRevisionFragment$data } from "@/web-form/utils/__generated__/webFormEditorUtils_getFormStateFromRevisionFragment.graphql"
import {
  ValidateWebFormRevisionInput,
  WebFormTemplate,
  webFormEditorUtils_validateWebFormRevisionInputMutation,
} from "@/web-form/utils/__generated__/webFormEditorUtils_validateWebFormRevisionInputMutation.graphql"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { toJS } from "mobx"
import { createContext, useCallback, useContext } from "react"
import { useMutation } from "react-relay"
import { graphql } from "relay-runtime"

export type WebFormEditorFormStore =
  | FormStore<{
      webFormRevision?: CreateWebFormRevisionInput | null
      content?: {
        webFormRevision?: CreateWebFormRevisionInput | null
      } | null
    }>
  | FormStore<CreateWebFormInput>

export type WebFormQuestionInput = Relay.DeepWriteable<ReadonlyWebFormQuestionInput> & {
  // Add a unique key for drag & drop since questions may not have IDs yet
  uniqueKey: string
  isExisting?: boolean
}

export type CreateWebFormInput = Omit<ReadonlyCreateWebFormInput, "questions"> & {
  questions: WebFormQuestionInput[]
}

export type CreateWebFormRevisionInput = Omit<
  ReadonlyCreateWebFormRevisionInput,
  "questions"
> & {
  questions: WebFormQuestionInput[]
}

type WebFormEditorRevisionData =
  | Omit<webFormEditorUtils_getFormStateFromRevisionFragment$data, " $fragmentType">
  | null
  | undefined

/**
 * Transform graphql web form revision data into form input compatible with web form
 * revision creation and updates
 */
export function getWebFormRevisionInput(
  revision: WebFormEditorRevisionData,
  contentUsageId?: GlobalID
): CreateWebFormRevisionInput | null {
  if (!revision) return null
  const questions = Relay.connectionToArray(revision.questions)
  return {
    webFormId: revision.webFormId,
    contentUsageId,
    questions: questions.map(
      (question) =>
        ({
          ...question,
          uniqueKey: question.id,
          isExisting: true,
        } as WebFormQuestionInput)
    ),
  }
}

/**
 * Remove temporary keys from questions input to prepare it for submission
 */
export function cleanWebFormQuestionsInput(
  questions: WebFormQuestionInput[]
): ReadonlyWebFormQuestionInput[] {
  return questions.map((question) => ({
    answerKey: question.answerKey,
    options: question.options,
    richEditorBody: question.richEditorBody,
    type: question.type,
    isRequired: question.isRequired,
  }))
}

export function useValidateWebFormRevisionInput(
  form: FormStore<any>,
  webFormRevision: CreateWebFormRevisionInput,
  type: WebFormTemplate,
  handlers?: { onError?: VoidFunction; onSuccess?: VoidFunction }
) {
  const [_validate, isValidating] =
    useMutation<webFormEditorUtils_validateWebFormRevisionInputMutation>(graphql`
      mutation webFormEditorUtils_validateWebFormRevisionInputMutation(
        $input: ValidateWebFormRevisionInput!
      ) {
        response: validateWebFormRevisionInput(input: $input) {
          errors {
            field
            message
          }
        }
      }
    `)

  const validate = useCallback(() => {
    const input: ValidateWebFormRevisionInput = {
      revision: {
        ...toJS(webFormRevision),
        questions: cleanWebFormQuestionsInput(toJS(webFormRevision.questions)),
      },
      webFormType: type,
    }
    form.removeErrors()
    _validate({
      variables: { input },
      onCompleted(res) {
        const { errors } = res.response
        if (errors) {
          form.addErrors(...errors)
          handlers?.onError?.()
        }
        if (!errors?.length) handlers?.onSuccess?.()
      },
      onError(err) {
        // if an unexpected error occurs for validation, log it and continue
        // the user will still be notified of the error when they try to save, but we don't want to block them from navigating away from the quiz question editor
        console.error(err)
        handlers?.onError?.()
      },
    })
  }, [form, _validate, webFormRevision, handlers, type])
  return [validate, isValidating] as const
}

/**
 * Web Form Editor Context
 */
type QuestionConfig = {
  disabled?: boolean
  classes?: ClassNameMap<"questionType">
  expanded?: boolean
}
type WebFormEditorContextValue = {
  template: WebFormTemplate
  form: WebFormEditorFormStore
  getQuestionConfig: (index: number) => QuestionConfig
  showQuestionRequiredToggle: boolean
}
const WebFormEditorContext = createContext<WebFormEditorContextValue | null>(null)

export function useWebFormEditorContext() {
  return useContext(WebFormEditorContext)
}

export const WebFormEditorProvider: React.FC<{
  form: WebFormEditorFormStore
  questionsConfig?: QuestionConfig[]
  template: WebFormTemplate
  showQuestionRequiredToggle?: boolean
}> = ({ form, questionsConfig, template, children, showQuestionRequiredToggle }) => {
  return (
    <WebFormEditorContext.Provider
      value={{
        template,
        form,
        getQuestionConfig: (index: number) => questionsConfig?.[index] || {},
        showQuestionRequiredToggle: showQuestionRequiredToggle ?? template !== "poll",
      }}
    >
      {children}
    </WebFormEditorContext.Provider>
  )
}

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment webFormEditorUtils_getFormStateFromRevisionFragment on WebFormRevision {
    webFormId
    questions {
      edges {
        node {
          ...webFormEditorUtils_questionFragment @relay(mask: false)
        }
      }
    }
  }
`
// eslint-disable-next-line no-unused-expressions
graphql`
  fragment webFormEditorUtils_questionFragment on WebFormQuestion {
    id
    type
    ordering
    richEditorBody
    isRequired
    options {
      id
      label
    }
    answerKey {
      correctIds
      feedback
    }
  }
`

export function getFirstWebFormQuestionError(form: WebFormEditorFormStore): {
  error: ValidationError | null
  questionIndex: number
} {
  for (const error of form.errors) {
    const match = error.field.match(/^questions\.(\d+)/)
    if (match) {
      return {
        error,
        questionIndex: Number(match[1]),
      }
    }
  }
  return { error: null, questionIndex: 0 }
}
