import { sendSentryAnException } from "@/core/sentryHandler"
import { TimezoneDropdownOption } from "@components/dropdown/timezone/TimezoneDropdown"
import { DATE_FORMAT } from "../time/timeConstants"
import { formatDateWithOptions } from "../time/timeUtils"

/**
 * Returns the browser's system timezone.
 * eg. 'Africa/Nairobi'
 */

function getLocalTimezone() {
  try {
    const tz = new Intl.DateTimeFormat().resolvedOptions().timeZone
    if (!isValidTimezone(tz)) throw new Error(`Invalid browser timezone: ${tz}`)
    return tz
  } catch (error) {
    // Our default local timezone if browser doesn't support Intl
    return "America/Toronto"
  }
}

/** Returns true if the string is a valid timezone like "America/Toronto" */
function isValidTimezone(timezone: string | null | undefined): timezone is string {
  if (!timezone) return false
  try {
    // eslint-disable-next-line no-new
    new Intl.DateTimeFormat(undefined, { timeZone: timezone })
    return true
  } catch {
    return false
  }
}

function getTimezoneDropdownOption(label: string): TimezoneDropdownOption | null {
  let option: TimezoneDropdownOption | null = null

  try {
    const differenceToGMT = formatDateWithOptions({
      timeZone: label,
      format: DATE_FORMAT.UTC_OFFSET,
    })(new Date())

    const title = label.replace(/_/g, " ")

    option = {
      value: label,
      title,
      searchable: [label, title, differenceToGMT],
      context: { subtitle: differenceToGMT },
    }
  } catch (error) {
    sendSentryAnException(`Invalid timezone '${label}': ${error}`)
  }

  return option
}

export { getLocalTimezone, getTimezoneDropdownOption }
