/**
 * @generated SignedSource<<457887d3dd64f46ea546b0cc1212fa17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateWebFormInput = {
  id: string;
  contentUsageId?: string | null;
  settings?: UpdateWebFormSettingsInput | null;
};
export type UpdateWebFormSettingsInput = {
  durationSeconds?: number | null;
  membersSeeAllResults?: boolean | null;
  endDate?: string | null;
  allowSubmissionDeletion?: boolean | null;
};
export type UpdatePollSettingsMutation$variables = {
  input: UpdateWebFormInput;
};
export type UpdatePollSettingsMutation$data = {
  readonly response: {
    readonly node: {
      readonly " $fragmentSpreads": FragmentRefs<"PollWebForm_settingsFragment">;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type UpdatePollSettingsMutation = {
  variables: UpdatePollSettingsMutation$variables;
  response: UpdatePollSettingsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePollSettingsMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateWebFormResponse",
        "kind": "LinkedField",
        "name": "updateWebForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WebForm",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PollWebForm_settingsFragment"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePollSettingsMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateWebFormResponse",
        "kind": "LinkedField",
        "name": "updateWebForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WebForm",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WebFormSettings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "membersSeeAllResults",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowSubmissionDeletion",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7f7a709062914fcdd008d566903ffcc3",
    "id": null,
    "metadata": {},
    "name": "UpdatePollSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePollSettingsMutation(\n  $input: UpdateWebFormInput!\n) {\n  response: updateWebForm(input: $input) {\n    node {\n      ...PollWebForm_settingsFragment\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment PollWebForm_settingsFragment on WebForm {\n  settings {\n    membersSeeAllResults\n    endDate\n    allowSubmissionDeletion\n  }\n}\n"
  }
};
})();

(node as any).hash = "77694045ccb9d2af621885d700a2f300";

export default node;
