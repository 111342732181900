import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import WebFormInput from "@/web-form/filler/WebFormInput"
import { PollOptionsFragment$key } from "@/web-form/filler/poll/__generated__/PollOptionsFragment.graphql"
import { PollOptionsMutation } from "@/web-form/filler/poll/__generated__/PollOptionsMutation.graphql"
import { PollOptions_refreshResultsQuery } from "@/web-form/filler/poll/__generated__/PollOptions_refreshResultsQuery.graphql"
import DeleteWebFormSubmissionButton from "@/web-form/submission/DeleteWebFormSubmissionButton"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoText, DiscoTextButton } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import useInterval from "@utils/hook/useInterval"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { getDifferenceToNowInWords } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { isPast, isSameYear } from "date-fns"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useEffect, useState } from "react"
import { commitLocalUpdate, graphql, useFragment } from "react-relay"

const RESULTS_REFRESH_INTERVAL_MS = 1000 * 15

type Props = TestIDProps & {
  questionKey: PollOptionsFragment$key
  createdByUserId?: GlobalID | null
  settings?: {
    membersSeeAllResults: boolean | null
    endDate: string | null
    allowSubmissionDeletion: boolean | null
  }
  disabled?: boolean
}

function PollOptions(props: Props) {
  const { testid = "PollOptions", questionKey, createdByUserId, settings } = props
  const { authUser } = useAuthUser()
  const timezone = useUserTimezone()
  const classes = useStyles()
  const theme = useTheme()

  const question = useFragment<PollOptionsFragment$key>(
    graphql`
      fragment PollOptionsFragment on WebFormQuestion {
        id
        type
        webFormRevisionId
        options {
          id
          label
        }
        answers {
          edges {
            node {
              id
              webFormQuestionId
              webFormSubmissionId
              selectedOptions
              userId
              user {
                ...UserAvatarFragment @relay(mask: false)
              }
            }
          }
        }
      }
    `,
    questionKey
  )
  const answers = Relay.connectionToArray(question.answers)
  const viewerAnswer = answers.find((a) => a.userId === authUser?.id)

  const form = useFormStore<PollOptionsMutation>(
    graphql`
      mutation PollOptionsMutation($input: CreateWebFormSubmissionInput!) {
        response: createWebFormSubmission(input: $input) {
          node {
            id
            webFormRevision {
              questions(first: 1) {
                edges {
                  node {
                    ...PollOptionsFragment
                  }
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      webFormRevisionId: question.webFormRevisionId,
      answers: [{ webFormQuestionId: question.id, selectedOptions: [] }],
    }
  )

  const viewerIsCreator = createdByUserId === authUser?.id

  const endDate = settings?.endDate ? new Date(settings.endDate) : null
  const [hasEnded, setHasEnded] = useState(calcHasEnded())
  useEffect(() => {
    setHasEnded(calcHasEnded())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate])

  const showResults = Boolean(
    viewerIsCreator || (settings?.membersSeeAllResults && (viewerAnswer || hasEnded))
  )
  const disabled = Boolean(props.disabled || viewerAnswer || hasEnded)
  const textColor =
    theme.palette.type === "dark" ? "groovy.onDark.200" : "groovy.neutral.400"

  // Set up a query to periodically refresh the poll results in the background
  useInterval(
    async () => {
      // Also check if the poll has ended so the form will auto-disable
      setHasEnded(calcHasEnded())

      await Relay.runQuery<PollOptions_refreshResultsQuery>(
        graphql`
          query PollOptions_refreshResultsQuery($id: ID!) {
            question: node(id: $id) {
              ...PollOptionsFragment
            }
          }
        `,
        { id: question.id },
        { fetchPolicy: "network-only" }
      )
    },
    RESULTS_REFRESH_INTERVAL_MS,
    { shouldStartInterval: showResults && !hasEnded }
  )

  return (
    <Form onSubmit={handleSubmit}>
      <div className={classes.promptContainer}>
        <DiscoText variant={"body-sm"} color={textColor} testid={`${testid}.prompt`}>
          {getPrompt()}
        </DiscoText>
        <DiscoText variant={"body-sm"} color={textColor} testid={`${testid}.status`}>
          {getStatus()}
        </DiscoText>
      </div>
      <WebFormInput
        testid={testid}
        question={question}
        answer={
          viewerAnswer
            ? (viewerAnswer as Relay.DeepWriteable<typeof viewerAnswer>)
            : form.state.answers![0]
        }
        disabled={disabled}
        allAnswers={showResults ? answers : undefined}
        variant={"poll"}
      />
      {!disabled && (
        <Form.SubmitButton
          testid={`${testid}.submit`}
          form={form}
          className={classes.submitButton}
        >
          {"Submit"}
        </Form.SubmitButton>
      )}
      {viewerAnswer && settings?.allowSubmissionDeletion && !hasEnded && (
        <div className={classes.deleteButtonContainer}>
          <DeleteWebFormSubmissionButton
            submissionId={viewerAnswer.webFormSubmissionId}
            confirmDelete={false}
            onRemove={handleDelete}
          >
            {(buttonProps) => (
              <DiscoTextButton
                {...buttonProps}
                testid={`${testid}.delete-submission`}
                textVariant={"body-sm"}
                variant={"currentColor"}
              >
                {"Remove response"}
              </DiscoTextButton>
            )}
          </DeleteWebFormSubmissionButton>
        </div>
      )}
    </Form>
  )

  function getPrompt() {
    if (showResults) return `${answers.length} total ${pluralize("vote", answers.length)}`
    if (viewerAnswer) return "Thanks for voting!"
    if (hasEnded) return ""
    if (question.type === "multiple_select") return "Select one or more options below"
    return "Select an option below"
  }

  function getStatus() {
    if (!endDate) return ""
    const endFormatted = getDifferenceToNowInWords(
      {
        timeZone: timezone,
        dateFormat: isSameYear(endDate, new Date())
          ? DATE_FORMAT.DATE_WITH_SHORT_TIME_FORMAT_AT
          : DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_AT,
        includeTimeForTodayOrTomorrow: true,
      },
      endDate
    )
    if (hasEnded) return `Ended ${endFormatted}`
    return `Ends ${endFormatted}`
  }

  async function handleSubmit() {
    const { didSave, response } = await form.submit(form.state)
    if (!didSave || !response?.node) return
    displaySuccessToast({
      message: "Response saved!",
      testid: `${testid}.success`,
    })
  }

  function handleDelete() {
    // Reset the form's selected answers state so selecting the same answer will
    // count as a change and the submit button won't be disabled
    form.state.answers![0].selectedOptions = observable([])
    form.resetInitialState()

    // Remove the user's answer record from the Relay store so they can answer again
    if (!viewerAnswer?.webFormQuestionId) return
    commitLocalUpdate(RelayEnvironment, (store) => {
      const questionRecord = store.get(viewerAnswer.webFormQuestionId)
      const answersConnection = questionRecord?.getLinkedRecord("answers")
      if (!answersConnection) return
      Relay.deleteNodeFromConnection(answersConnection, viewerAnswer.id)
    })
  }

  function calcHasEnded() {
    return endDate ? isPast(endDate) : false
  }
}

const useStyles = makeUseStyles((theme) => ({
  submitButton: {
    width: "100%",
    marginTop: theme.spacing(2.5),
  },
  promptContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1.5),
  },
  deleteButtonContainer: {
    textAlign: "right",
    marginTop: theme.spacing(1),
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.grey[300]
        : theme.palette.groovy.grey[400],
  },
}))

export default observer(PollOptions)
