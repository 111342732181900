/**
 * @generated SignedSource<<8605ae8e2a98f9620c95158a11f0fb5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type webFormSubmissionsUtils_useCountQuery$variables = {
  webFormId: string;
  include: boolean;
  contentUsageId?: string | null;
  excludeUsageScope?: boolean | null;
  uniqueByMember?: boolean | null;
  completed?: boolean | null;
  passed?: boolean | null;
};
export type webFormSubmissionsUtils_useCountQuery$data = {
  readonly node?: {
    readonly __typename: "WebForm";
    readonly id: string;
    readonly submissionsCount: number;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type webFormSubmissionsUtils_useCountQuery = {
  variables: webFormSubmissionsUtils_useCountQuery$variables;
  response: webFormSubmissionsUtils_useCountQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "completed"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentUsageId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeUsageScope"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "include"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "passed"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uniqueByMember"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "webFormId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "webFormId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "completed",
      "variableName": "completed"
    },
    {
      "kind": "Variable",
      "name": "contentUsageId",
      "variableName": "contentUsageId"
    },
    {
      "kind": "Variable",
      "name": "excludeUsageScope",
      "variableName": "excludeUsageScope"
    },
    {
      "kind": "Variable",
      "name": "passed",
      "variableName": "passed"
    },
    {
      "kind": "Variable",
      "name": "uniqueByMember",
      "variableName": "uniqueByMember"
    }
  ],
  "kind": "ScalarField",
  "name": "submissionsCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "webFormSubmissionsUtils_useCountQuery",
    "selections": [
      {
        "condition": "include",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "type": "WebForm",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "webFormSubmissionsUtils_useCountQuery",
    "selections": [
      {
        "condition": "include",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v10/*: any*/)
                ],
                "type": "WebForm",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "46254ca9722a3891f84f33681063e869",
    "id": null,
    "metadata": {},
    "name": "webFormSubmissionsUtils_useCountQuery",
    "operationKind": "query",
    "text": "query webFormSubmissionsUtils_useCountQuery(\n  $webFormId: ID!\n  $include: Boolean!\n  $contentUsageId: ID\n  $excludeUsageScope: Boolean\n  $uniqueByMember: Boolean\n  $completed: Boolean\n  $passed: Boolean\n) {\n  node(id: $webFormId) @include(if: $include) {\n    __typename\n    ... on WebForm {\n      __typename\n      id\n      submissionsCount(contentUsageId: $contentUsageId, excludeUsageScope: $excludeUsageScope, uniqueByMember: $uniqueByMember, completed: $completed, passed: $passed)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d39d7b1b805351004f21bad7fa4df5d6";

export default node;
