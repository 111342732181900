import { WebFormAnswerVariant } from "@/web-form/filler/WebFormAnswer"
import { WebFormQuestionAnswers } from "@/web-form/filler/WebFormInput"
import AnswerOptionStats, {
  useWebFormAnswerOptionStats,
} from "@/web-form/filler/answer-inputs/AnswerOptionStats"
import { indexToLetter } from "@/web-form/utils/webFormFillerUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoIcon, DiscoRadio, DiscoText } from "@disco-ui"
import { RadioGroup } from "@material-ui/core"
import { hexOpacity } from "@utils/color/colorUtils"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"

type Props = TestIDProps & {
  options: readonly { id: string; label: string }[]
  answer: { selectedOptions?: string[] | null }
  variant?: WebFormAnswerVariant
  disabled?: boolean
  allAnswers?: WebFormQuestionAnswers
}

function SingleSelectAnswerInput(props: Props) {
  const {
    options,
    answer,
    testid = "SingleSelectAnswerInput",
    variant = "default",
    disabled,
    allAnswers,
  } = props
  const optionStats = useWebFormAnswerOptionStats(options, allAnswers)
  const classes = useStyles({ variant, showAnswers: Boolean(allAnswers), disabled })
  const selected = answer.selectedOptions?.length ? answer.selectedOptions[0] : ""

  return (
    <RadioGroup
      value={selected}
      onChange={(e) => (answer.selectedOptions = [e.target.value])}
    >
      {options.map((option, i) => (
        <DiscoRadio
          key={option.id}
          value={option.id}
          label={
            <>
              {optionStats[option.id] && (
                <div
                  className={classes.percentBar}
                  style={{ width: `${optionStats[option.id].percent}%` }}
                />
              )}
              <div className={classes.innerLabel}>
                <DiscoText
                  variant={variant === "compact" ? "body-sm" : "body-md"}
                  testid={`${testid}.option.${i}.label`}
                >
                  {option.label}
                </DiscoText>
                {optionStats[option.id] && (
                  <AnswerOptionStats
                    testid={`${testid}.option.${i}.stats`}
                    stats={optionStats[option.id]}
                    isSelected={option.id === selected}
                  />
                )}
              </div>
            </>
          }
          controlClasses={{
            root: classNames(classes.radioRoot, {
              [classes.radioRootChecked]: option.id === selected,
            }),
            label: classes.label,
          }}
          classes={{ icon: classes.radioIcon }}
          uncheckedIcon={
            <span className={classNames(classes.radioIcon, classes.uncheckedRadioIcon)}>
              {indexToLetter(i)}
            </span>
          }
          checkedIcon={
            disabled ? (
              <span className={classNames(classes.radioIcon, classes.checkedRadioIcon)}>
                <DiscoIcon icon={"check"} height={22} width={22} />
              </span>
            ) : undefined
          }
          testid={`${testid}.option.${i}.input`}
          disabled={disabled}
        />
      ))}
    </RadioGroup>
  )
}

type StyleProps = {
  variant: WebFormAnswerVariant
  showAnswers?: boolean
  disabled?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  radioRoot: ({ variant, disabled }: StyleProps) => ({
    padding: theme.spacing(
      variant === "compact" ? 0 : variant === "poll" ? 0.25 : 0.75,
      0.75
    ),
    margin: `${theme.spacing(0, 0, variant === "poll" ? 1 : 1.5)} !important`,
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: variant === "poll" ? undefined : theme.palette.groovyDepths.insideCard,
    position: "relative",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      borderColor: disabled
        ? theme.palette.groovy.neutral[300]
        : theme.palette.primary.main,
    },
    "&:last-child": {
      marginBottom: "0 !important",
    },
  }),
  radioRootChecked: ({ variant, showAnswers, disabled }: StyleProps) => ({
    "&, &:hover": {
      borderColor: theme.palette.primary.main,
    },
    ...styleIf(variant === "poll", {
      backgroundColor:
        showAnswers || disabled ? hexOpacity(theme.palette.primary.main, 0.1) : undefined,
      "& $percentBar": {
        backgroundColor: hexOpacity(theme.palette.primary.main, 0.1),
      },
    }),
  }),
  radioIcon: {
    height: "24px",
    width: "24px",
    // Needed to raise the icon above percentBar
    zIndex: 1,
  },
  uncheckedRadioIcon: {
    backgroundColor: theme.palette.groovy.neutral[100],
    border: `1px solid ${
      theme.palette.type === "dark"
        ? theme.palette.groovy.grey[500]
        : theme.palette.groovy.neutral[200]
    }`,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    color: theme.palette.common.black,
  },
  checkedRadioIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      color: theme.palette.primary.contrastText,
    },
  },
  label: {
    display: "block",
  },
  innerLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: theme.spacing(0.75),
    position: "relative",
  },
  percentBar: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.neutral[300]
        : theme.palette.groovy.neutral[100],
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    transition: "width 0.5s ease-in-out",
  },
}))

export default observer(SingleSelectAnswerInput)
