import UserAvatarStack from "@/user/common/avatar-stack/UserAvatarStack"
import { UserAvatarShape } from "@/user/common/avatar/UserAvatar"
import { WebFormQuestionAnswers } from "@/web-form/filler/WebFormInput"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"
import { useMemo } from "react"

type OptionStats = {
  count: number
  percent: number
  users: UserAvatarShape[]
}

type Props = TestIDProps & {
  stats: OptionStats
  isSelected: boolean
}

export default function AnswerOptionStats(props: Props) {
  const { testid = "AnswerOptionStats", stats, isSelected } = props
  const classes = useStyles()
  const isMobile = useIsMobile()
  const theme = useTheme()

  return (
    <div className={classes.stats}>
      <UserAvatarStack
        testid={`${testid}.AvatarStack`}
        users={stats.users}
        totalUsers={stats.users.length}
        unstyledButton
        stackSize={3}
        countVariant={isMobile ? "placeholder" : "never"}
      />
      <DiscoText
        testid={testid}
        variant={"body-sm-500"}
        color={
          isSelected
            ? "text.primary"
            : theme.palette.type === "dark"
            ? "groovy.onDark.200"
            : "groovy.neutral.400"
        }
      >
        {isMobile
          ? `${stats.percent}%`
          : `${stats.count} ${pluralize("vote", stats.count)} · ${stats.percent}%`}
      </DiscoText>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  stats: {
    display: "flex",
    gap: theme.spacing(1.5),
    alignItems: "center",
    flexShrink: 0,
  },
}))

export function useWebFormAnswerOptionStats(
  options: readonly { id: string; label: string }[],
  allAnswers: WebFormQuestionAnswers | undefined
) {
  return useMemo(() => {
    const stats: Record<string, OptionStats> = {}
    if (!allAnswers) return stats

    for (const option of options) {
      const optionAnswers = allAnswers.filter((a) =>
        a.selectedOptions?.includes(option.id)
      )
      stats[option.id] = {
        count: optionAnswers.length,
        percent: allAnswers.length
          ? Math.round((optionAnswers.length / allAnswers.length) * 100)
          : 0,
        users: optionAnswers.map((a) => a.user),
      }
    }
    return stats
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length, allAnswers?.length])
}
