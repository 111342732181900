import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

type Props = TestIDProps & {
  options: readonly { id: string; label: string }[]
  selected: readonly string[] | null
}

function WebFormAnswerRankingResult(props: Props) {
  const { testid = "WebFormAnswerRankingResult", options } = props

  // If they skipped the question show the default order
  const isSkipped = !props.selected?.length
  const selectedOrder = isSkipped ? options.map((o) => o.id) : props.selected!
  const classes = useStyles({ isSkipped })

  return (
    <div data-testid={testid} className={classes.container}>
      {selectedOrder.map((id, i) => {
        const option = options.find((o) => o.id === id)!
        return (
          <div key={id} data-testid={`${testid}.option.${i}`} className={classes.option}>
            <div className={classes.orderNumber}>
              <DiscoText variant={"body-sm"} color={"primary.contrastText"}>
                {isSkipped ? "" : i + 1}
              </DiscoText>
            </div>
            <div className={classes.label}>
              <DiscoText variant={"body-sm"}>{option.label}</DiscoText>
            </div>
          </div>
        )
      })}
    </div>
  )
}

type StyleProps = {
  isSkipped: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  option: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  orderNumber: ({ isSkipped }: StyleProps) => ({
    height: 24,
    width: 24,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: isSkipped ? undefined : theme.palette.primary.main,
    border: `1px solid ${
      isSkipped
        ? theme.palette.groovy.neutral[theme.palette.type === "dark" ? 500 : 300]
        : theme.palette.primary.main
    }`,
  }),
  label: {
    flexGrow: 1,
    padding: theme.spacing(1),
    border: `1px solid ${
      theme.palette.groovy.neutral[theme.palette.type === "dark" ? 500 : 300]
    }`,
    borderRadius: theme.measure.borderRadius.medium,
  },
}))

export default WebFormAnswerRankingResult
