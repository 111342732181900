import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import {
  Entitlement,
  useHasEntitlementActiveOrganizationFragment$key,
} from "@utils/hook/__generated__/useHasEntitlementActiveOrganizationFragment.graphql"
import { graphql, useFragment } from "react-relay"

export default function useHasEntitlement(entitlement: Entitlement | undefined | null) {
  const organization = useFragment<useHasEntitlementActiveOrganizationFragment$key>(
    graphql`
      fragment useHasEntitlementActiveOrganizationFragment on Organization {
        entitlements
      }
    `,
    useActiveOrganization()
  )

  if (!organization || !entitlement) return false
  return organization.entitlements.includes(entitlement)
}
