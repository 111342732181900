import ReorderIcon from "@/core/ui/iconsax/linear/custom-dots-tune.svg"
import WebFormEditorQuestionOptions from "@/web-form/editor/WebFormEditorQuestionOptions"
import WebFormEditorQuestionType from "@/web-form/editor/WebFormEditorQuestionType"
import WebFormEditorRatingOptions from "@/web-form/editor/WebFormEditorRatingOptions"
import {
  useWebFormEditorContext,
  WebFormQuestionInput,
} from "@/web-form/utils/webFormEditorUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { LexicalConfigType } from "@components/editor/config/LexicalConfig"
import DiscoEditor from "@components/editor/DiscoEditor"
import {
  DiscoFormControl,
  DiscoIcon,
  DiscoIconButton,
  DiscoSwitch,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import { useMediaQuery, useTheme } from "@material-ui/core"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { ReactNode, useEffect, useState } from "react"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"

type Props = {
  question: WebFormQuestionInput
  questionIndex: number
  dragHandleProps?: DraggableProvidedDragHandleProps
  onDuplicate: (questionIndex: number) => void
  onDelete: (questionIndex: number) => void
  customControls?: ReactNode
  customTitle?: ReactNode
  editorConfig?: LexicalConfigType
  showAIGenerateButton?: boolean
  readonly?: boolean
}

function WebFormEditorQuestion(props: Props) {
  const {
    question,
    questionIndex,
    dragHandleProps,
    onDuplicate,
    onDelete,
    customControls,
    customTitle,
    showAIGenerateButton,
    editorConfig = "web_form_question",
    readonly,
  } = props
  const showOnHoverClasses = useShowOnHoverStyles()
  const theme = useTheme()
  const TESTID = `WebFormEditorQuestion.${questionIndex}`
  const { form, getQuestionConfig, showQuestionRequiredToggle } =
    useWebFormEditorContext()!
  const config = getQuestionConfig(questionIndex)
  const [isExpanded, setIsExpanded] = useState(Boolean(config.expanded))
  useEffect(() => {
    if (config.expanded !== undefined) setIsExpanded(config.expanded)
  }, [config.expanded])

  // Expand the question when the config changes or it has an error within it
  const { errorsByField } = form
  const questionErrors = errorsByField[`questions.${questionIndex}`]
  const richEditorErrors = errorsByField[`questions.${questionIndex}.richEditorBody`]
  const hasError = Boolean(questionErrors || richEditorErrors) || hasOptionError()
  useEffect(() => {
    if (hasError) setIsExpanded(true)
  }, [hasError])

  const classes = useStyles({
    isExpanded,
    hasError: Boolean(questionErrors),
    disabled: config.disabled,
  })
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true })

  const options = renderOptions()

  return (
    <div className={classNames(classes.root, showOnHoverClasses.hoverable)}>
      <div className={classes.top}>
        <div className={classes.upperTop} {...dragHandleProps}>
          <div>
            <WebFormEditorQuestionType
              testid={TESTID}
              questionIndex={questionIndex}
              questionType={question.type}
            />
          </div>
          <div className={showOnHoverClasses.showable}>
            {!isXsDown && <ReorderIcon className={classes.dragHandleIcon} />}
            {customControls}
          </div>
          <div>
            <DiscoIconButton
              testid={`${TESTID}.expand`}
              classes={{ root: classes.iconButton }}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <DiscoIcon icon={"chevron"} className={classes.expandIcon} />
            </DiscoIconButton>
          </div>
        </div>
        {customTitle && <div>{customTitle}</div>}
        <div className={classes.expandShow}>
          <DiscoFormControl
            marginBottom={0}
            error={Boolean(richEditorErrors)}
            errorMessages={richEditorErrors}
            label={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"Question"}
              </DiscoText>
            }
            labelClasses={{ root: classes.questionLabel }}
          >
            <DiscoEditor
              testid={`${TESTID}.rich-editor-body`}
              placeholder={"Type your question here..."}
              defaultValue={question.richEditorBody}
              onChange={(value) => (question.richEditorBody = value)}
              readOnly={config.disabled || readonly}
              className={classes.richEditor}
              variant={"input"}
              hasError={Boolean(richEditorErrors)}
              minHeight={80}
              config={editorConfig}
            />
          </DiscoFormControl>
        </div>
      </div>
      {isExpanded && (
        <>
          {options && <div className={classes.middle}>{options}</div>}
          <div className={classes.bottom}>
            {showQuestionRequiredToggle && (
              <DiscoSwitch
                testid={`${TESTID}.required-toggle`}
                checked={question.isRequired ?? false}
                onChange={(v) => (question.isRequired = v)}
                label={<DiscoText color={"text.secondary"}>{"Required"}</DiscoText>}
                labelPlacement={"end"}
                disabled={config.disabled || readonly}
              />
            )}
            <div className={classes.questionError}>
              <DiscoText variant={"body-sm"} color={"error.dark"}>
                {questionErrors?.[0]}
              </DiscoText>
            </div>
            <div className={classes.buttons}>
              <DiscoTooltip content={"Duplicate this question"}>
                <DiscoIconButton
                  testid={`${TESTID}.duplicate`}
                  classes={{ root: classes.iconButton }}
                  onClick={() => onDuplicate(questionIndex)}
                  disabled={config.disabled || readonly}
                >
                  <DiscoIcon icon={"copy"} />
                </DiscoIconButton>
              </DiscoTooltip>
              <DiscoTooltip content={"Delete this question"}>
                <DiscoIconButton
                  testid={`${TESTID}.delete`}
                  classes={{ root: classes.iconButton }}
                  onClick={() => onDelete(questionIndex)}
                  disabled={config.disabled || readonly}
                >
                  <DiscoIcon icon={"trash"} />
                </DiscoIconButton>
              </DiscoTooltip>
            </div>
          </div>
        </>
      )}
    </div>
  )

  function renderOptions() {
    switch (question.type) {
      case "written_answer":
      case "link":
        return null
      case "single_select":
      case "multiple_select":
      case "ranking":
        return (
          <WebFormEditorQuestionOptions
            testid={TESTID}
            question={question}
            questionIndex={questionIndex}
            showAIGenerateButton={showAIGenerateButton}
            readonly={readonly}
          />
        )
      case "rating":
        return (
          <WebFormEditorRatingOptions
            testid={TESTID}
            question={question}
            questionIndex={questionIndex}
            readonly={readonly}
          />
        )
      default:
        throw new Error(`Invalid question type '${question.type}'`)
    }
  }

  function hasOptionError() {
    if (!question.options) return false
    return question.options.some(
      (_, i) => errorsByField[`questions.${questionIndex}.options.${i}`]
    )
  }
}

type StyleProps = {
  isExpanded: boolean
  hasError: boolean
  disabled?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.measure.borderRadius.medium,
    boxShadow: theme.palette.groovyDepths.insideCard,
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
  },
  top: ({ isExpanded }: StyleProps) => ({
    padding: theme.spacing(2),
    borderBottom: isExpanded ? theme.palette.constants.borderSmall : 0,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  }),
  upperTop: {
    position: "relative",
    display: "flex",
    gap: theme.spacing(1),
    "& > div": {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    "& > div:nth-child(2)": {
      flexGrow: 1,
      justifyContent: "center",
    },
  },
  expandShow: ({ isExpanded }: StyleProps) => ({
    ...styleIf(!isExpanded, {
      display: "none",
    }),
  }),
  middle: {
    padding: theme.spacing(2),
    borderBottom: theme.palette.constants.borderSmall,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bottom: ({ hasError }: StyleProps) => ({
    padding: theme.spacing(1.5, 2),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(3),
    ...styleIf(hasError, {
      backgroundColor: theme.palette.error.light,
    }),
  }),
  questionError: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    padding: 0,
    height: "40px",
    width: "40px",
  },
  dragHandleIcon: ({ disabled }: StyleProps) => ({
    height: "32px",
    position: "absolute",
    top: theme.spacing(-0.5),
    padding: theme.spacing(1),
    left: "50%",
    transform: "translate(-50%) rotate(90deg)",
    color: theme.palette.groovy.grey[disabled ? 300 : 400],
  }),
  expandIcon: ({ isExpanded }: StyleProps) => ({
    transition: "transform 300ms ease-in-out",
    transform: `rotate(${isExpanded ? 180 : 90}deg)`,
  }),
  richEditor: ({ disabled }: StyleProps) => ({
    ...styleIf(disabled, {
      opacity: 0.4,
    }),
  }),
  questionLabel: {
    marginBottom: theme.spacing(0.5),
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(0.5),
  },
}))

export default observer(WebFormEditorQuestion)
