import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { useEffect, useState } from "react"
import { commitLocalUpdate } from "relay-runtime"

function useZoomConnectionOnWindowFocus(
  initialIsConnectZoomAccountButtonClickedState = false
) {
  const activeOrganization = useActiveOrganization()

  const [isConnectZoomAccountButtonClicked, setConnectZoomAccountButtonState] = useState(
    initialIsConnectZoomAccountButtonClickedState
  )

  useEffect(() => {
    if (isConnectZoomAccountButtonClicked) {
      window.addEventListener("focus", handleWindowFocus)
    } else if (activeOrganization?.hasZoomIntegration) {
      window.removeEventListener("focus", handleWindowFocus)
    }

    return () => {
      window.removeEventListener("focus", handleWindowFocus)
    }

    function handleWindowFocus() {
      if (!activeOrganization) return

      // Invalidate connection record to force refetch from Relay Store
      commitLocalUpdate(RelayEnvironment, (store) => {
        const org = store.get(activeOrganization.id)
        if (!org) return

        const meetingProviderConnection = org.getLinkedRecord("meetingProviders", {
          platform: "zoom",
        })
        if (!meetingProviderConnection) return
        meetingProviderConnection.invalidateRecord()

        // Set organization.hasZoomIntegration to show info on admin integrations page
        if (org.getValue("hasZoomIntegration") === false) {
          org.setValue(true, "hasZoomIntegration")
        }
      })

      setConnectZoomAccountButtonState(false)
    }
  }, [isConnectZoomAccountButtonClicked, activeOrganization])

  return [isConnectZoomAccountButtonClicked, setConnectZoomAccountButtonState] as const
}

export default useZoomConnectionOnWindowFocus
