import { WEB_FORM_RATING_SCALE } from "@/web-form/utils/webFormConstants"
import { ratingOptionDisplayNumber } from "@/web-form/utils/webFormFillerUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

type Props = TestIDProps & {
  options: readonly { id: string; label: string }[]
  selected: readonly string[] | null
}

function WebFormAnswerRatingResult(props: Props) {
  const { testid = "WebFormAnswerRatingResult", options, selected } = props
  const classes = useStyles()
  const selectedIndex = selected?.length
    ? options.findIndex((o) => o.id === selected[0])
    : -1
  const labelOptions = options.filter((o) => o.label)

  return (
    <div className={classes.scaleContainer}>
      <div>
        <div className={classes.scaleLabels}>
          {labelOptions.map((option) => (
            <DiscoText key={option.id} variant={"body-xs"} color={"groovy.neutral.400"}>
              {option.label}
            </DiscoText>
          ))}
        </div>
        <div
          className={classNames(
            classes.scale,
            options.length > WEB_FORM_RATING_SCALE.SMALL
              ? classes.largeScale
              : classes.smallScale
          )}
        >
          {options.map((option, i) => {
            const isSelected = i <= selectedIndex
            return (
              <div
                key={option.id}
                className={classNames(classes.option, { [classes.selected]: isSelected })}
                data-testid={`${testid}.option.${i}${isSelected ? ".selected" : ""}`}
              >
                <DiscoText color={"common.black"}>
                  {ratingOptionDisplayNumber(i, options.length)}
                </DiscoText>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  scaleContainer: {
    display: "flex",
    justifyContent: "center",
    "& > div": {
      maxWidth: "100%",
    },
  },
  scaleLabels: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2.5),
    marginBottom: theme.spacing(0.5),
  },
  scale: {
    display: "flex",
    justifyContent: "center",
  },
  largeScale: {
    gap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(0.25),
      "& $option": {
        height: 24,
        width: 24,
      },
    },
  },
  smallScale: {
    gap: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(3),
    },
  },
  option: {
    height: 40,
    width: 40,
    border: `1px solid ${theme.palette.common.black}`,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: 32,
      width: 32,
    },
  },
  selected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    "& > *": {
      color: theme.palette.primary.contrastText,
    },
  },
}))

export default WebFormAnswerRatingResult
