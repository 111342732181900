import QuizSubmissionIncompleteTile from "@/content-usage/drawer/quizzes/submission-result/QuizSubmissionIncompleteTile"
import { WEB_FORM_QUESTION_TYPE_TITLES } from "@/web-form/utils/useWebFormQuestionTypes"
import {
  WEB_FORM_QUESTION_TYPE_PROMPTS,
  WebFormQuestionData,
} from "@/web-form/utils/webFormFillerUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { DiscoChip, DiscoSection, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"

export interface WebFormQuestionProps extends TestIDProps {
  answerNode?: React.ReactNode
  question: WebFormQuestionData
  readOnly?: boolean
  totalQuestionsCount?: number
  classes?: Partial<
    ClassNameMap<"container" | "questionSection" | "answerHeader" | "answerDetails">
  >
  showNotAnswered?: boolean
  answerDetails?: React.ReactNode
  moreActions?: React.ReactNode
}

function WebFormQuestion({
  testid = "WebFormQuestion",
  question,
  answerNode,
  readOnly = false,
  totalQuestionsCount,
  classes: customClasses,
  showNotAnswered = false,
  answerDetails,
  moreActions,
}: WebFormQuestionProps) {
  const classes = useStyles({ readOnly })
  const theme = useTheme()
  const showProgressHeader = !readOnly && typeof totalQuestionsCount === "number"

  return (
    <div data-testid={`${testid}.container`} className={customClasses?.container}>
      <DiscoSection
        className={classNames(classes.question, customClasses?.questionSection)}
        padding={readOnly ? 0 : 2}
        marginBottom={2.25}
      >
        {showProgressHeader && (
          <div className={classes.header}>
            <DiscoText
              variant={"body-xs-500-uppercase"}
              color={"groovy.neutral.500"}
              component={"p"}
            >{`Question ${question.ordering + 1} of ${totalQuestionsCount}`}</DiscoText>
            {question.isRequired && (
              <DiscoChip testid={`${testid}.required`} label={"Required"} />
            )}
          </div>
        )}
        <div className={classes.questionNum}>
          <DiscoText
            testid={`${testid}.question-number`}
            component={"span"}
            color={readOnly ? "text.primary" : "primary.contrastText"}
            variant={readOnly ? "body-md-600" : "body-md"}
          >
            {question.ordering + 1}
          </DiscoText>
        </div>
        <div className={classes.questionContent}>
          <DiscoEditor
            testid={`${testid}.question-body`}
            defaultValue={question.richEditorBody}
            readOnly
            disableGutter
            backgroundColor={"transparent"}
          />
        </div>
        <div className={classes.moreActions}>
          {moreActions ||
            (!showProgressHeader && question.isRequired ? (
              <DiscoChip testid={`${testid}.required`} label={"Required"} />
            ) : null)}
        </div>
      </DiscoSection>
      {answerNode && (
        <div>
          <div className={classNames(classes.answerHeader, customClasses?.answerHeader)}>
            {showNotAnswered && (
              <QuizSubmissionIncompleteTile
                testid={`${testid}.question-not-answered`}
                title={"Question not answered"}
              />
            )}
            <div
              className={classNames(classes.answerDetails, customClasses?.answerDetails)}
            >
              {answerDetails ?? (
                <DiscoText
                  variant={"body-sm"}
                  color={
                    theme.palette.type === "dark"
                      ? "groovy.onDark.200"
                      : "groovy.neutral.400"
                  }
                >
                  {readOnly
                    ? WEB_FORM_QUESTION_TYPE_TITLES[question.type]
                    : WEB_FORM_QUESTION_TYPE_PROMPTS[question.type]}
                </DiscoText>
              )}
            </div>
          </div>
          {answerNode}
        </div>
      )}
    </div>
  )
}

type StyleProps = {
  readOnly: boolean
}

const useStyles = makeUseStyles((theme) => ({
  question: ({ readOnly }: StyleProps) => ({
    backgroundColor: "transparent",
    display: "grid",
    gridTemplateAreas: `'h h h h'
      'n c c m'`,
    gridTemplateColumns: "min-content 1fr 1fr min-content",
    columnGap: theme.spacing(1.5),
    border: readOnly ? undefined : `1px solid ${theme.palette.groovy.neutral[300]}`,
  }),
  header: {
    gridArea: "h",
    marginBottom: theme.spacing(1),
    display: "flex",
    gap: theme.spacing(1.5),
    alignItems: "center",
  },
  questionNum: ({ readOnly }: StyleProps) => ({
    gridArea: "n",
    backgroundColor: readOnly
      ? theme.palette.groovy.neutral[100]
      : theme.palette.primary.main,
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(1),
    height: "40px",
    minWidth: "40px",
    maxWidth: "fit-content",
    display: "grid",
    placeItems: "center",
  }),
  questionContent: {
    gridArea: "c",
  },
  moreActions: {
    gridArea: "m",
    justifySelf: "end",

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    gap: theme.spacing(1),
    width: "100%",
  },
  answerHeader: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  answerDetails: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
}))

export default observer(WebFormQuestion)
