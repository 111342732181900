import { useEffect, useRef } from "react"

const RESIZE_LISTENER_DELAY = 400

function useOnWindowResize(callback: VoidFunction) {
  const timeoutId = useRef<any>(undefined)

  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
      clearTimeout(timeoutId.current)
    }

    function handleResize() {
      if (!timeoutId.current) {
        timeoutId.current = setTimeout(() => {
          callback()
          timeoutId.current = undefined
        }, RESIZE_LISTENER_DELAY)
      }
    }
  }, [callback])
}

/* USAGE:

  useOnWindowResize(
    useCallback(() => {
      console.log(window.innerWidth)
      console.log(window.innerHeight)
    }, [])
  );

*/

export default useOnWindowResize
