import { SLUG_DISALLOWED_CHARACTER_REGEX, URL_REGEX } from "./stringConstants"

/**
 * @param {string} text A string
 * @returns {string} Initial letters of the words within text
 */
function getInitialLettersOfWords(text: string): string {
  return text
    .split(/\s/g)
    .filter(Boolean)
    .map((word) => word[0].toLocaleUpperCase())
    .join("")
}

function suffixCountInParantheses(
  predicateFn: (countToCheck?: number) => boolean,
  count: number,
  text: string
): string {
  return predicateFn(count) ? `${text} (${count})` : text
}

function generateRandomString(length = 10, radix = 36) {
  if (length > 10) throw new Error("Length cannot be greater than 10")
  return Math.random().toString(radix).slice(-length)
  /* eslint-enable no-magic-numbers */
}

function wordStartsWithVowel(string: string) {
  const vowels = ["a", "e", "i", "o", "u"]
  return vowels.includes(string[0].toLowerCase())
}

/**
 * Capitalizes a word
 * @param {string} word Some word to be capitalized
 * @returns {string} Capitalized version of the provided word
 */
function capitalizeWord(word: string) {
  return `${word[0].toUpperCase()}${word.substring(1)}`
}

/**
 * Takes a text, removes any character except numbers, letters, hyphens and underscores  and returns lowercased and hyphened version of it
 * @param {string} text A string to sluggify
 */
function sluggifyText(text: string): string {
  return text
    .toLowerCase()
    .trim()
    .split(" ")
    .join("-")
    .replace(SLUG_DISALLOWED_CHARACTER_REGEX, "")
}

/**
 * Checks for links within a text and wraps them with an anchor tag
 * @param {string} text
 */
function linkifyText(text: string) {
  return text.replace(
    URL_REGEX,
    `<a href="$1" target="_blank" rel=”noreferrer noopener” class="linkified-text__link">$1</a>`
  )
}

/**
 * Takes a URL and appends a protocol to it
 * @param {string} url A url
 * @param {string} [protocol] A URI scheme, defaults to "http"
 *
 */
function absolutizeURL(url: string, protocol = "https") {
  let absoluteUrl = url

  if (!/^https|http|mailto|tel?:/i.test(url)) {
    absoluteUrl = `${protocol}://${url}`
  }

  return absoluteUrl
}

/**
 * turns https://www.youtube.com/ -> youtube.com
 */
function stripURL(url?: string) {
  if (!url) return null

  let newLink = ""

  // remove protocol, ie. http:// or https://
  newLink = url.replace(/(^\w+:|^)\/\//, "").replace(/\/$/, "")

  // remove www.
  newLink = newLink.replace(/www./, "")

  // remove trailing forward slash
  newLink = newLink.replace(/\/$/, "")

  return newLink
}

/**
 * Truncates and adds a suffix to the provided text if text.length > limit
 */
function truncateText(
  { limit, suffix = "..." }: { limit: number; suffix?: string },
  text: string
): string {
  return text.length > limit ? `${text.slice(0, limit - suffix.length)}${suffix}` : text
}

/**
 * Truncates middle of text if text.length > limit
 */
function truncateTextMiddle(text: string, limit: number): string {
  if (text.length <= limit) {
    return text
  }
  const truncationLength = limit - 5 // Reserve space for ellipsis
  const leftHalfLength = Math.ceil(truncationLength / 2)
  const rightHalfLength = Math.floor(truncationLength / 2)
  return `${text.slice(0, leftHalfLength)}[...]${text.slice(-rightHalfLength)}`
}

/**
 * Retrieves all the text from a string of markdown.
 */
function getTextFromMarkdown(markdown: string) {
  const text = markdown
    .replace(/^### (.*$)/gim, "$1")
    .replace(/^## (.*$)/gim, "$1")
    .replace(/^# (.*$)/gim, "$1")
    .replace(/^> (.*$)/gim, "$1")
    .replace(/\*\*(.*)\*\*/gim, "$1")
    .replace(/\*(.*)\*/gim, "$1")
    .replace(/!\[(.*?)\]\((.*?)\)/gim, "$1 from $2")
    .replace(/\[(.*?)\]\((.*?)\)/gim, "$1")
    .replace(/\n$/gim, "")
    .replace(/\\$/gim, "")
  return text.trim()
}

/**
 * Escape special characters in a string
 *
 * @param {string} text A string
 * @returns {string} escaped string
 */
function escapeRegExp(text: string) {
  return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

/**
 * Concatenate two test id together
 */
function concatenateTestId(parentTestId: string | undefined, testId: string) {
  return `${parentTestId ? `${parentTestId}.` : ""}${testId}`
}

function convertCamelCaseToSnakeCase(string: string): string {
  return string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

// unicode-safe base64 encoding
function base64Encode(str: string) {
  const encode = encodeURIComponent(str).replace(/%([a-f0-9]{2})/gi, (_, $1) =>
    String.fromCharCode(parseInt($1, 16))
  )
  return btoa(encode)
}

// unicode-safe base64 decoding
function base64Decode(str: string) {
  const decode = atob(str).replace(
    /[\x80-\uffff]/g,
    (m) => `%${m.charCodeAt(0).toString(16).padStart(2, "0")}`
  )
  return decodeURIComponent(decode)
}

/** Converts input to title case */
function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

/**
 * Converts a list into a single string combining
 * the last element with the word 'and'
 */
function stringifyArray(array: string[]) {
  if (array.length === 1) return array[0]
  return `${array.slice(0, -1).join(", ")} and ${array.slice(-1)}`
}

export {
  absolutizeURL,
  base64Decode,
  base64Encode,
  capitalizeWord,
  concatenateTestId,
  convertCamelCaseToSnakeCase,
  escapeRegExp,
  generateRandomString,
  getInitialLettersOfWords,
  getTextFromMarkdown,
  linkifyText,
  sluggifyText,
  stripURL,
  stringifyArray,
  suffixCountInParantheses,
  toTitleCase,
  truncateText,
  wordStartsWithVowel,
  truncateTextMiddle,
}
