/**
 * @generated SignedSource<<6eba9c61f33d38b5c42d9190bb488de2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WebFormQuestionType = "single_select" | "multiple_select" | "written_answer" | "link" | "rating" | "ranking" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PollOptionsFragment$data = {
  readonly id: string;
  readonly type: WebFormQuestionType;
  readonly webFormRevisionId: string;
  readonly options: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
  }> | null;
  readonly answers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly webFormQuestionId: string;
        readonly webFormSubmissionId: string;
        readonly selectedOptions: ReadonlyArray<string> | null;
        readonly userId: string;
        readonly user: {
          readonly id: string;
          readonly first_name: string | null;
          readonly last_name: string | null;
          readonly avatar: string;
        };
      };
    }>;
  };
  readonly " $fragmentType": "PollOptionsFragment";
};
export type PollOptionsFragment$key = {
  readonly " $data"?: PollOptionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PollOptionsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PollOptionsFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webFormRevisionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormQuestionOption",
      "kind": "LinkedField",
      "name": "options",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormAnswerNodeConnection",
      "kind": "LinkedField",
      "name": "answers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormAnswerNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormAnswer",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "webFormQuestionId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "webFormSubmissionId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "selectedOptions",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": "first_name",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": "last_name",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WebFormQuestion",
  "abstractKey": null
};
})();

(node as any).hash = "2deb129bb975554b93eb39017e61a974";

export default node;
