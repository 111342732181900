import { WebFormQuestionType } from "@/web-form/utils/__generated__/webFormEditorUtils_getFormStateFromRevisionFragment.graphql"
import { useWebFormQuestionTypes } from "@/web-form/utils/useWebFormQuestionTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDropdown, DiscoDropdownProps, DiscoText } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { TestIDProps } from "@utils/typeUtils"

type Props = TestIDProps &
  Pick<DiscoDropdownProps, "menuButton"> & {
    onSelect: (type: WebFormQuestionType) => void
    allowedTypes?: WebFormQuestionType[]
  }

export default function WebFormQuestionTypeDropdown(props: Props) {
  const {
    menuButton,
    onSelect,
    testid = "WebFormQuestionTypeDropdown",
    allowedTypes,
  } = props
  const classes = useStyles()
  let options = useWebFormQuestionTypes()
  if (allowedTypes) options = options.filter((o) => allowedTypes.includes(o.value))

  return (
    <DiscoDropdown
      testid={testid}
      menuButton={menuButton}
      horizontal={"center"}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {options.map((option) => (
        <DiscoDropdownItem
          key={option.value}
          testid={`${testid}.${option.title}`}
          onClick={() => onSelect(option.value)}
          className={classes.listItem}
        >
          <div className={classes.option}>
            {option.icon}
            <div>
              <DiscoText variant={"body-sm-500"}>{option.title}</DiscoText>
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {option.subtitle}
              </DiscoText>
            </div>
          </div>
        </DiscoDropdownItem>
      ))}
    </DiscoDropdown>
  )
}

const useStyles = makeUseStyles((theme) => ({
  option: {
    display: "flex",
    gap: theme.spacing(1),
  },
  listItem: {
    height: "auto !important",
  },
}))
