import { SECOND_IN_MS } from "@utils/time/timeConstants"
import { ComponentType, lazy } from "react"

const MAX_ATTEMPT = 5

/** Custome lazy component loader with retries */
const lazyLoader = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  retryAttempts = MAX_ATTEMPT
): Promise<{ default: T }> => {
  return new Promise((resolve, reject) => {
    factory()
      .then(resolve)
      .catch((err) => {
        setTimeout(() => {
          /** If all retries were unsuccessful, throw an error and let the error boundary component handle it */
          if (retryAttempts === 1) {
            reject(err)
          } else {
            lazyLoader(factory, retryAttempts - 1).then(resolve, reject)
          }
        }, (MAX_ATTEMPT - retryAttempts + 1) * SECOND_IN_MS * 2)
      })
  })
}

const lazyWithRetry = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
) => lazy(() => lazyLoader(factory))

export default lazyWithRetry
