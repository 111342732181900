/**
 * @generated SignedSource<<b1e75a8e83403cf4463e59c25ac0b706>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type webFormQueryUtils_answerFragment$data = {
  readonly id: string;
  readonly body: string | null;
  readonly selectedOptions: ReadonlyArray<string> | null;
  readonly webFormQuestionId: string;
  readonly isSkipped: boolean;
  readonly isCorrect: boolean | null;
  readonly result: {
    readonly correctIds: ReadonlyArray<string>;
    readonly incorrectSelections: ReadonlyArray<string>;
  } | null;
  readonly " $fragmentType": "webFormQueryUtils_answerFragment";
};
export type webFormQueryUtils_answerFragment$key = {
  readonly " $data"?: webFormQueryUtils_answerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"webFormQueryUtils_answerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "webFormQueryUtils_answerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedOptions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webFormQuestionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSkipped",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCorrect",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormAnswerResult",
      "kind": "LinkedField",
      "name": "result",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "correctIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "incorrectSelections",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WebFormAnswer",
  "abstractKey": null
};

(node as any).hash = "0031f4699d4ef512ae2137bb429516ca";

export default node;
